import React, { useState } from 'react';
import { Switch, useHistory, useLocation } from 'react-router-dom';

import Route from '../../../routes/Route';
import ChildDashboardContainer from '../../../components/ChildDashboardContainer';
import Header from '../../../components/Header';
import { activeTab } from '../common';
import Mensageria from './Mensageria';

const MensageriaRoutes: React.FC = () => {
    const history = useHistory();
    const location = useLocation();

    const [tabs, setTabs] = useState([
        {
            name: 'Mensageria',
            link: '/dashboard/mensageria',
            active: location.pathname === '/dashboard/mensageria',
        },
        {
            name: 'Entregas',
            link: '/dashboard/mensageria/entregas',
            active: location.pathname === '/dashboard/mensageria/entregas',
        },
        {
            name: 'Documentos Registrados',
            link: '/dashboard/mensageria/documentosRegistrados',
            active:
                location.pathname === '/dashboard/mensageria/documentosRegistrados',
        },
        {
            name: 'Acompanhamento',
            link: '/dashboard/mensageria/acompanhamento',
            active: location.pathname === '/dashboard/mensageria/acompanhamento',
        },
        {
            name: 'Registro',
            link: '/dashboard/mensageria/registro',
            active: location.pathname === '/dashboard/mensageria/registro',
        },
    ]);

    const changeTab = (name: string): void => {
        setTabs(activeTab(name, tabs));

        const { link } = tabs.find(tab => tab.name === name)!;

        history.push(link);
    };

    return (
        <>
            <Header tabs={tabs} changeTab={changeTab} />
            <ChildDashboardContainer>
                <Switch>
                    <Route
                        path="/dashboard/mensageria"
                        exact
                        component={Mensageria}
                        isPrivate
                    />
                </Switch>
            </ChildDashboardContainer>
        </>
    );
};

export default MensageriaRoutes;
