import styled from 'styled-components';
import colors from '../../../styles/colors';

export const HeaderTitleContent = styled.div`
    display: flex;
    justify-content: space-between;
    width: 100%;
`;

export const TitleContent = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
    justify-content: center;
`;

export const Title = styled.p`
    font-size: 33px;
    color: ${colors.color_3F577B};
`;

export const RedefinePasswordPasswordImg = styled.img`
    width: 100%;
    height: 100%;
    max-width: 241px;
    max-height: 200px;
`;

export const PasswordSuccessfullyResetImg = styled.img`
    width: 100%;
    height: 100%;
    max-width: 250px;
    max-height: 200px;
`;

export const DescriptionPasswordSuccessfullyReset = styled.p`
    margin-top: 20px;
    font-size: 28px;
    color: ${colors.color_606060};
`;
