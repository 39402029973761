import styled from 'styled-components';
import colors from '../../../../../styles/colors';
import { heightContent, widthImageContent } from '../styles';

export const Container = styled.div`
    padding: 60px 5px 0 110px;
    display: flex;
    flex-direction: column;
    width: calc(100% - ${widthImageContent}px - 182px);
    height: calc(${heightContent}px - 100px);
`;

export const Title = styled.p`
    font-size: 27px;
    color: ${colors.color_3F577B};
`;

export const UnderlinedTitle = styled.div`
    width: 150px;
    height: 3px;
    background-color: ${colors.color_4BB493};
`;

export const SubTitleContent = styled.div`
    margin: 15px auto 0 15px;
    padding: 0 5px;
    display: flex;
    background-color: ${colors.color_FFFFFF};
    z-index: 1;
`;

export const SubTitle = styled.p`
    font-size: 14px;
    color: ${colors.color_000000};
`;

export const CompanyDataContent = styled.div`
    margin-top: -10px;
    display: flex;
    width: 100%;
    max-height: 210px;
    border: 1px solid ${colors.color_D9E8D9};
    border-radius: 5px;
`;

export const InputsContent = styled.div`
    margin: 15px;
    padding-bottom: 10px;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-content: stretch;
    flex-wrap: wrap;
    gap: 14px;
    width: 100%;

    @media (max-width: 1599px) {
        overflow-y: scroll;

        ::-webkit-scrollbar {
            width: 7px;
            height: 7px;
        }

        ::-webkit-scrollbar-thumb {
            background: ${colors.color_3F577B};
            border-radius: 5px;
            transition: 0.5s;
        }

        ::-webkit-scrollbar-track {
            background: ${colors.color_F5F6F8};
            border: 1px solid ${colors.color_BCE0FD};
            border-radius: 5px;
        }
    }
`;
