import styled from 'styled-components';
import colors from '../../styles/colors';

export const Container = styled.div`
    width: 100%;
`;

export const Title = styled.p`
    margin-left: 15px;
    font-size: 30px;
    color: ${colors.color_3F577B};
`;

export const ServiceContainer = styled.div`
    margin-left: auto;
    width: 100%;
    max-width: 557px;
    height: 69px;
    display: flex;
    flex-direction: column;
    align-items: flex-end;
`;

export const ServicesTitleText = styled.p`
    font-size: 15px;
    color: ${colors.color_3F577B};
`;

export const ServicesContent = styled.div`
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
`;
