import styled from 'styled-components';
import colors from '../../../styles/colors';

export const HeaderTitleContent = styled.div`
    display: flex;
    justify-content: space-between;
    width: 100%;
`;

export const TitleContent = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
`;

export const Title = styled.p`
    font-size: 35px;
    color: ${colors.color_3F577B};
`;

export const LogoForgotPasswordImg = styled.img`
    width: 100%;
    height: 100%;
    max-width: 200px;
    max-height: 139px;
`;

export const SendEmailContent = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;
    justify-content: center;
    align-items: center;
`;

export const TitleSendEmail = styled.p`
    margin-top: -130px;
    font-size: 39px;
    color: ${colors.color_3F577B};
`;

export const DescriptionSendEmail = styled.p`
    width: 100%;
    max-width: 746px;
    margin-top: 30px;
    text-align: center;
    font-size: 28px;
    color: ${colors.color_606060};
`;

export const ResendEmailButton = styled.button`
    border: none;
    font-size: 28px;
    color: ${colors.color_1976D2};
    text-decoration: underline;
    background-color: transparent;
`;

export const LogoSendEmailImg = styled.img`
    width: 100%;
    height: 100%;
    max-width: 770px;
    max-height: 345px;
`;

export const LogoMandeSeguroSendEmailImg = styled.img`
    margin-top: 50px;
    width: 100%;
    height: 100%;
    max-width: 284px;
    max-height: 98px;
`;
