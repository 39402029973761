import styled from 'styled-components';
import colors from '../../../../styles/colors';

export const Container = styled.div`
    display: flex;
    flex-direction: column;
    height: 100%;

    overflow-x: auto;
    overflow-y: auto;

    ::-webkit-scrollbar {
        width: 7px;
        height: 7px;
    }

    ::-webkit-scrollbar-thumb {
        background: ${colors.color_3F577B};
        border-radius: 5px;
        transition: 0.5s;
    }

    ::-webkit-scrollbar-track {
        background: ${colors.color_F5F6F8};
        border: 1px solid ${colors.color_BCE0FD};
        border-radius: 5px;
    }
`;

export const QuickActionsContent = styled.div`
    margin-top: -60px;
    display: flex;
    align-items: center;
    gap: 80px;

    @media (max-width: 1330px) {
        margin-top: 0px;
    }
`;

export const HeaderContent = styled.div`
    display: flex;
    flex-direction: column;
    max-width: 269px;
`;

export const HeaderTitle = styled.p`
    font-size: 33px;
    color: ${colors.color_3F577B};
`;

export const HeaderSubTitle = styled.p`
    font-size: 12px;
    color: ${colors.color_3F577B};
`;

export const Content = styled.div`
    display: flex;
    flex-direction: column;
    margin-top: 10px;
    padding: 30px;
    background-color: ${colors.color_FFFFFF};
    border-radius: 5px;
    box-shadow: 0px 3px 6px #00000029;
`;

export const ScanCodeContent = styled.div`
    display: flex;
    flex-direction: column;
`;

export const TitleScanCodeText = styled.p`
    font-size: 15px;
    color: ${colors.color_000000};
`;

export const SubTitleScanCodeText = styled.p`
    font-size: 9px;
    color: ${colors.color_172B4D};
`;

export const TableContent = styled.table`
    margin-top: 25px;
    background-color: ${colors.color_FFFFFF};
    border: 1px solid ${colors.color_D5E3D6};
    border: none;
`;

export const TableHeaderContent = styled.thead`
    display: flex;
    padding: 9px 0;
    position: sticky;
    background-color: ${colors.color_3F577B};
    display: flex;
    align-items: center;
    z-index: 10;
`;

export const TableHeaderText = styled.tr`
    flex: 1;
    font-size: 16px;
    color: ${colors.color_FFFFFF};
`;

export const TableBodyContent = styled.tbody`
    display: flex;
    flex-direction: column;
    max-height: 153px;
    width: 100%;
    overflow-y: scroll;

    overflow-x: auto;
    overflow-y: auto;

    ::-webkit-scrollbar {
        width: 7px;
        height: 7px;
    }

    ::-webkit-scrollbar-thumb {
        background: ${colors.color_3F577B};
        border-radius: 5px;
        transition: 0.5s;
    }

    ::-webkit-scrollbar-track {
        background: ${colors.color_F5F6F8};
        border: 1px solid ${colors.color_BCE0FD};
        border-radius: 5px;
    }
`;

export const TableLineContent = styled.tr`
    display: flex;
    width: 100%;
    min-height: 50px;
    align-items: center;
    border-bottom: 1px solid ${colors.color_D5E3D6};
`;

export const TableLineText = styled.td`
    flex: 1;
    font-size: 13px;
    color: ${colors.color_172B4D};
`;

export const StatusTableContent = styled.div`
    display: flex;
    width: 74px;
    min-height: 24px;
    align-items: center;
    justify-content: center;
    border-radius: 11px;
    border: 1px solid ${colors.color_EBEBEB};
`;

export const OpenMaloteButton = styled.button`
    width: 92px;
    height: 30px;
    font-size: 9px;
    color: ${colors.color_172B4D};
    background-color: ${colors.color_FFFFFF};
    border-radius: 4px;
    border: 1px solid ${colors.color_EBEBEB};
`;

export const StatusTableText = styled.p`
    font-size: 11px;
    font-weight: bold;
`;

export const IconContent = styled.div`
    flex: 0.5;
    display: flex;
    justify-content: flex-end;
`;

export const Icon = styled.img`
    margin-right: 20px;
    align-items: flex-end;
    width: 15px;
    height: 15px;
`;
