import { FormHandles } from '@unform/core';
import { Form } from '@unform/web';
import React, { useCallback, useRef, useState } from 'react';
import { useHistory } from 'react-router-dom';
import * as Yup from 'yup';

import LogoMandeSeguroWhite from '../../../assets/svgs/LogoMandeSeguroWhite.svg';
import LogoMandeSeguro from '../../../assets/svgs/LogoMandeSeguro.svg';
import RedefinePasswordPassword from '../../../assets/svgs/RedefinePassword.svg';
import PasswordSuccessfullyReset from '../../../assets/svgs/PasswordSuccessfullyReset.svg';

import Input from '../../../components/Input';
import getValidationErrors from '../../../utils/getValidationErros';

import {
    Title,
    TitleContent,
    HeaderTitleContent,
    RedefinePasswordPasswordImg,
    PasswordSuccessfullyResetImg,
    DescriptionPasswordSuccessfullyReset,
} from './styles';

import { useToast } from '../../../hooks/toast';
import {
    Body,
    ClientButton,
    ClientText,
    Container,
    Content,
    ContentWallpaper,
    Header,
    HeaderItenContent,
    HeaderItenText,
    LoginButton,
    LoginContent,
    LogoMandeSeguroImg,
    LogoMandeSeguroWhiteContent,
    LogoMandeSeguroWhiteImg,
} from '../styles';

interface RedefinePasswordFormData {
    password: string;
    confirmPassword: string;
}

const RedefinePassword: React.FC = () => {
    const formRef = useRef<FormHandles>(null);
    const [redefinePassword, setRedifePassword] = useState(true);

    const history = useHistory();
    const { addToast } = useToast();

    const handleSubmit = useCallback(
        async (data: RedefinePasswordFormData) => {
            try {
                formRef.current?.setErrors({});
                const schema = Yup.object().shape({
                    password: Yup.string().required('Senha obrigatória'),
                    confirmPassword: Yup.string().required(
                        'Confirmação da senha obrigatória',
                    ),
                });

                await schema.validate(data, {
                    abortEarly: false,
                });

                // await api.put('password/reset-password', {
                //     email: ,
                //     password: data.password,
                //     token:
                // });

                setRedifePassword(false);
            } catch (err) {
                if (err instanceof Yup.ValidationError) {
                    const errors = getValidationErrors(err);

                    formRef.current?.setErrors(errors);

                    return;
                }

                addToast({
                    type: 'error',
                    title: 'Erro no envio do e-mail',
                    description:
                        'Ocorreu um erro ao tentar enviar o e-mail, por favor tente novamente',
                });
            }
        },
        [addToast, setRedifePassword],
    );

    const handleLogin = useCallback(() => {
        history.push('/');
    }, [history]);

    return (
        <Container>
            <Content>
                <>
                    <LogoMandeSeguroImg
                        onClick={() => history.push(`/`)}
                        src={LogoMandeSeguro}
                        alt="LogoMandeSeguro"
                        style={{ cursor: 'pointer' }}
                    />
                    <Body>
                        <LoginContent>
                            {redefinePassword ? (
                                <>
                                    <HeaderTitleContent>
                                        <TitleContent>
                                            <Title>Redefinir senha</Title>
                                            <Title>enviada por e-mail</Title>
                                        </TitleContent>
                                        <RedefinePasswordPasswordImg
                                            src={RedefinePasswordPassword}
                                            alt="LogoForgotPassword"
                                        />
                                    </HeaderTitleContent>

                                    <Form
                                        ref={formRef}
                                        onSubmit={handleSubmit}
                                        style={{
                                            width: '100%',
                                            height: '100%',
                                        }}
                                    >
                                        <Input
                                            title="Nova Senha"
                                            name="password"
                                            type="password"
                                            placeholder="Digite a sua nova senha"
                                            containerStyle={{
                                                marginTop: '30px',
                                                width: '100%',
                                            }}
                                        />
                                        <Input
                                            title="Confirmar Senha"
                                            name="confirmPassword"
                                            type="password"
                                            placeholder="Confirme sua nova senha"
                                            containerStyle={{
                                                marginTop: '30px',
                                                width: '100%',
                                            }}
                                        />
                                        <LoginButton type="submit">
                                            REDEFINIR SENHA
                                        </LoginButton>
                                    </Form>
                                </>
                            ) : (
                                <>
                                    <HeaderTitleContent>
                                        <TitleContent>
                                            <Title>Senha redefinida</Title>
                                            <Title>com sucesso!</Title>
                                        </TitleContent>
                                        <PasswordSuccessfullyResetImg
                                            src={PasswordSuccessfullyReset}
                                            alt="LogoForgotPassword"
                                        />
                                    </HeaderTitleContent>
                                    <DescriptionPasswordSuccessfullyReset>
                                        Clique abaixo que redirecionaremos você para
                                        efetuar o login. Qual quer problema estamos a
                                        disposição.
                                    </DescriptionPasswordSuccessfullyReset>
                                    <LoginButton onClick={handleLogin}>
                                        FAZER LOGIN
                                    </LoginButton>
                                </>
                            )}
                        </LoginContent>
                    </Body>
                </>
            </Content>
            <ContentWallpaper>
                <Header>
                    <HeaderItenContent>
                        <HeaderItenText>SOBRE NÓS</HeaderItenText>
                        <HeaderItenText>REGISTRO DE OBJETOS</HeaderItenText>
                        <HeaderItenText>FALE CONOSCO</HeaderItenText>
                    </HeaderItenContent>
                    <ClientButton>
                        <ClientText>SEJA UM CLIENTE</ClientText>
                    </ClientButton>
                </Header>
                <LogoMandeSeguroWhiteContent>
                    <LogoMandeSeguroWhiteImg
                        src={LogoMandeSeguroWhite}
                        alt="LogoMandeSeguro"
                    />
                </LogoMandeSeguroWhiteContent>
            </ContentWallpaper>
        </Container>
    );
};

export default RedefinePassword;
