import React, { ButtonHTMLAttributes, CSSProperties } from 'react';
import { Container } from './styles';

interface ButtonProps extends ButtonHTMLAttributes<HTMLButtonElement> {
    title: string;

    typeButton?: 'primary' | 'light';
    containerStyle?: CSSProperties;
}

const Button: React.FC<ButtonProps> = ({
    title,
    typeButton,
    containerStyle,
    ...rest
}) => {
    return (
        <Container
            type="button"
            typeButton={typeButton || 'primary'}
            style={{ ...containerStyle }}
            {...rest}
        >
            {title}
        </Container>
    );
};

export default Button;
