import { FormHandles } from '@unform/core';
import { Form } from '@unform/web';
import React, { useRef } from 'react';
import Button from '../../../../components/Button';
import HeaderChildDashboard from '../../../../components/HeaderChildDashboard';
import RegisterButton from '../../../../components/RegisterButton';
import SimpleInput from '../../../../components/SimpleInput';
import colors from '../../../../styles/colors';
import {
    Container,
    Content,
    HeaderContent,
    HeaderTitle,
    HeaderSubTitle,
    QuickActionsContent,
    StatusTableContent,
    StatusTableText,
    SubTitleScanCodeText,
    TableBodyContent,
    TableContent,
    TableHeaderContent,
    TableHeaderText,
    TableLineContent,
    TableLineText,
    TitleScanCodeText,
    ScanCodeContent,
    OpenMaloteButton,
} from './styles';

const malotes = [
    {
        status: 'Recebido',
        origin: 'Rio de Janeiro/RJ',
        destination: 'Belo Horizonte/MG',
        tag: 'RJ02',
        route: 'Rio de Janeiro / Belo Horizonte',
    },
    {
        status: 'Finalizado',
        origin: 'Montes Claros/MG',
        destination: 'Belo Horizonte/MG',
        tag: 'MOC02',
        route: 'Belo Horizonte / Montes Claros',
    },
    {
        status: 'Em Recebimento',
        origin: 'Rio de Janeiro/RJ',
        destination: 'Belo Horizonte/MG',
        tag: 'RJ02',
        route: 'Rio de Janeiro / Belo Horizonte',
    },
];

const Recebimentos: React.FC = () => {
    const formRef = useRef<FormHandles>(null);

    return (
        <Container>
            <HeaderChildDashboard title="" activeMalote={true} />
            <QuickActionsContent>
                <HeaderContent>
                    <HeaderTitle>Recebimento</HeaderTitle>
                    <HeaderSubTitle>
                        REGISTRE OS RECEBIMENTOS ENVIOS, APÓS CADA ITEM RECEVIDO O
                        MALOTE SERÁ FINALIZADO
                    </HeaderSubTitle>
                </HeaderContent>
                <RegisterButton
                    title="REGISTRAR NOVO RECEBIMENTO"
                    subTitle="CLIQUE PARA EFETUAR O RECEBIMENTO DE NOVOS ITENS"
                />
            </QuickActionsContent>
            <Content>
                <Form
                    ref={formRef}
                    style={{
                        marginTop: '10px',
                        display: 'flex',
                        alignItems: 'flex-end',
                    }}
                    onSubmit={() => {
                        // eslint-disable-next-line no-console
                        console.log('code');
                    }}
                >
                    <ScanCodeContent>
                        <TitleScanCodeText>
                            Escaneie / Digite o espelho do malote
                        </TitleScanCodeText>
                        <SimpleInput
                            name="codeMalote"
                            containerStyle={{
                                height: '42px',
                                margin: '0px 30px 0 0',
                            }}
                        />
                    </ScanCodeContent>
                    <ScanCodeContent>
                        <TitleScanCodeText>
                            Escaneie / Digite o código lacre do malote
                        </TitleScanCodeText>
                        <SimpleInput
                            name="codeLacre"
                            containerStyle={{
                                height: '42px',
                                margin: '0px 30px 0 0',
                            }}
                        />
                    </ScanCodeContent>
                    <Button
                        title="RECEBER MALOTE"
                        style={{
                            height: '42px',
                            width: '166px',
                            color: `${colors.color_000000}`,
                        }}
                        typeButton="light"
                    />
                </Form>
                <SubTitleScanCodeText>
                    Sucesso malote encontrado na base de registro
                </SubTitleScanCodeText>

                <TableContent>
                    <TableHeaderContent>
                        <TableHeaderText style={{ marginLeft: '50px', flex: '0.5' }}>
                            MALOTE
                        </TableHeaderText>
                        <TableHeaderText>LOCALIDADE DE ORIGEM</TableHeaderText>
                        <TableHeaderText>LOCALIDADE DESTINO</TableHeaderText>
                        <TableHeaderText>
                            ESPELHO IDENTIFICADO MALOTE
                        </TableHeaderText>
                        <TableHeaderText>ROTA</TableHeaderText>
                        <TableHeaderText />
                    </TableHeaderContent>
                    <TableBodyContent>
                        {malotes.map((malote, index) => (
                            <TableLineContent key={index}>
                                <TableLineText
                                    style={{ marginLeft: '50px', flex: '0.5' }}
                                >
                                    <StatusTableContent>
                                        <StatusTableText>
                                            {malote.status}
                                        </StatusTableText>
                                    </StatusTableContent>
                                </TableLineText>
                                <TableLineText>{malote.origin}</TableLineText>
                                <TableLineText>{malote.destination}</TableLineText>
                                <TableLineText>{malote.tag}</TableLineText>
                                <TableLineText>{malote.route}</TableLineText>
                                <TableLineText>
                                    <OpenMaloteButton>Abrir Malote</OpenMaloteButton>
                                </TableLineText>
                            </TableLineContent>
                        ))}
                    </TableBodyContent>
                </TableContent>
            </Content>
            <Content>
                <TitleScanCodeText>
                    Escaneie / Digite a etiqueta dos itens dentro do malote
                </TitleScanCodeText>
                <Form
                    ref={formRef}
                    style={{
                        marginTop: '10px',
                        display: 'flex',
                    }}
                    onSubmit={() => {
                        // eslint-disable-next-line no-console
                        console.log('code');
                    }}
                >
                    <SimpleInput
                        name="code"
                        containerStyle={{
                            width: '670px',
                            height: '42px',
                            margin: '0px 30px 0 0',
                        }}
                    />
                    <Button
                        title="Receber"
                        style={{ height: '42px', width: '155px' }}
                        typeButton="light"
                        disabled
                    />
                </Form>
            </Content>
        </Container>
    );
};

export default Recebimentos;
