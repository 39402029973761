import React, { CSSProperties } from 'react';
import { Container, Icon, Title } from './styles';

interface ServiceCardProps {
    title: string;
    icon: string;
    active?: boolean;
    containerStyle?: CSSProperties;
}

const ServiceCard: React.FC<ServiceCardProps> = props => {
    return (
        <Container active={props.active} style={{ ...props.containerStyle }}>
            <Title>{props.title}</Title>
            <Icon src={props.icon} alt="icon" />
        </Container>
    );
};

export default ServiceCard;
