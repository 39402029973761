import React, { useState } from 'react';
import { Switch, useHistory, useLocation } from 'react-router-dom';

import Route from '../../../routes/Route';
import ChildDashboardContainer from '../../../components/ChildDashboardContainer';
import Header from '../../../components/Header';
import { activeTab } from '../common';
import HomeSettings from './HomeSettings';
import UsersSettings from './UsersSettings';
import AccountsSettings from './AccountsSettings';
import ClientSettings from './ClientSettings';
import RouteRegistration from './RouteRegistration';

const SettingsRoutes: React.FC = () => {
    const history = useHistory();
    const location = useLocation();

    const [tabs, setTabs] = useState([
        {
            name: 'Home',
            link: '/dashboard/settings',
            active: location.pathname === '/dashboard/settings',
        },
        {
            name: 'Contas',
            link: '/dashboard/settings/contas',
            active: location.pathname === '/dashboard/settings/contas',
        },
        {
            name: 'Clientes',
            link: '/dashboard/settings/clientes',
            active: location.pathname === '/dashboard/settings/clientes',
        },
        {
            name: 'Cadastro de usúario',
            link: '/dashboard/settings/cadastroDeUsuario',
            active: location.pathname === '/dashboard/settings/cadastroDeUsuario',
        },
        {
            name: 'Cadastro de Rotas',
            link: '/dashboard/settings/rotas',
            active: location.pathname === '/dashboard/settings/rotas',
        },
    ]);

    const changeTab = (name: string): void => {
        setTabs(activeTab(name, tabs));

        const { link } = tabs.find(tab => tab.name === name)!;

        history.push(link);
    };

    return (
        <>
            <Header tabs={tabs} changeTab={changeTab} />
            <ChildDashboardContainer>
                <Switch>
                    <Route
                        path="/dashboard/settings"
                        exact
                        component={HomeSettings}
                        isPrivate
                    />
                    <Route
                        path="/dashboard/settings/contas"
                        exact
                        component={AccountsSettings}
                        isPrivate
                    />
                    <Route
                        path="/dashboard/settings/clientes"
                        exact
                        component={ClientSettings}
                        isPrivate
                    />
                    <Route
                        path="/dashboard/settings/cadastroDeUsuario"
                        exact
                        component={UsersSettings}
                        isPrivate
                    />
                    <Route
                        path="/dashboard/settings/rotas"
                        exact
                        component={RouteRegistration}
                        isPrivate
                    />
                </Switch>
            </ChildDashboardContainer>
        </>
    );
};

export default SettingsRoutes;
