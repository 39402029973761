import React from 'react';

import { Container, Title, UnderlinedTitle, InputsContent } from './styles';
import colors from '../../../../../styles/colors';
import Input from '../../../../../components/Input';
import Select from '../../../../../components/Select';

interface PhysicalPerson {
    name: string;
    email: string;
    identifier: string;
    permissions: string;
    phone_number: string;
    company_id: string;
    department: string;
    country: string;
    gender: string;
    password: string;
    confirm_password: string;
}

const PhysicalPerson: React.FC = () => {
    return (
        <Container>
            <Title>Digite os dados a baixo</Title>
            <UnderlinedTitle />
            <p
                style={{
                    fontSize: '14px',
                    marginTop: '45px',
                    color: `${colors.color_000000}`,
                }}
            >
                DADOS PESSOAIS
            </p>
            <InputsContent>
                <Input
                    title="Name*"
                    name="physicalPerson.name"
                    placeholder="Digite o seu nome"
                />
                <Input
                    title="E-mail*"
                    name="physicalPerson.email"
                    placeholder="Digite o seu E-mail"
                />
                <Input
                    title="CPF*"
                    name="physicalPerson.identifier"
                    placeholder="Digite o seu CPF"
                    containerStyle={{ width: '169px' }}
                />
                <Select
                    title="Permissões*"
                    name="physicalPerson.permissions"
                    placeholder="Selecione"
                    containerStyle={{ width: '169px' }}
                    options={[
                        {
                            title: 'Adiministrador',
                            value: 'admin',
                        },
                        { title: 'User', value: 'user' },
                    ]}
                />
                <Input
                    title="Contato*"
                    name="physicalPerson.phone_number"
                    placeholder="Digite o numero de contato"
                />
                <Input
                    title="Empresa*"
                    name="physicalPerson.company_id"
                    placeholder="Digite o nome da empresa"
                />
                <Input
                    title="Departamento*"
                    name="physicalPerson.department"
                    placeholder="Departamento"
                />
                <Select
                    title="Região/País*"
                    name="physicalPerson.country"
                    placeholder="Selecione"
                    options={[
                        {
                            title: 'Brasil',
                            value: 'brasil',
                        },
                    ]}
                />
                <Select
                    title="Sexo*"
                    name="physicalPerson.gender"
                    placeholder="Selecione"
                    containerStyle={{ width: '169px' }}
                    options={[
                        {
                            title: 'Masculino',
                            value: 'M',
                        },
                        {
                            title: 'Feminino',
                            value: 'F',
                        },
                    ]}
                />
                <div
                    style={{
                        display: 'flex',
                        flexWrap: 'wrap',
                        gap: '30px 14px',
                    }}
                >
                    <Input
                        title="Senha*"
                        name="physicalPerson.password"
                        placeholder="Digite a senha"
                        type="password"
                    />
                    <Input
                        title="Confirmação da Senha*"
                        name="physicalPerson.confirm_password"
                        placeholder="Digite a senha novamente"
                        type="password"
                    />
                </div>
            </InputsContent>
        </Container>
    );
};

export default PhysicalPerson;
