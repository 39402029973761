import React from 'react';
import { Switch } from 'react-router-dom';

import Route from './Route';
import Dashboard from '../pages/Dashboard/index.routes';
import SignIn from '../pages/Login/SignIn';
import ForgotPassword from '../pages/Login/ForgotPassword';
import RedefinePassword from '../pages/Login/RedefinePassword';

const Routes: React.FC = () => {
    return (
        <Switch>
            <Route path="/" exact component={SignIn} />
            <Route path="/forgotPassword" exact component={ForgotPassword} />
            <Route path="/redefinePassword" exact component={RedefinePassword} />
            <Route path="/dashboard" component={Dashboard} isPrivate />
        </Switch>
    );
};

export default Routes;
