import styled from 'styled-components';
import colors from '../../styles/colors';

export const Container = styled.div`
    width: 300px;
`;

export const ButtonContent = styled.button`
    border: none;
    display: flex;
    align-items: center;
    width: 100%;
    height: 54px;
    border-radius: 5px;
    font-size: 14px;
    background-color: ${colors.color_3F577B};

    &:hover {
        opacity: 0.9;
    }
`;

export const PlusContent = styled.div`
    margin: 0 15px;
    display: flex;
    align-items: center;
    justify-content: center;
    min-width: 25px;
    height: 25px;
    border-radius: 25px;
    background-color: ${colors.color_4BB493};
`;

export const Text = styled.div`
    font-size: 15px;
    color: ${colors.color_FFFFFF};
`;

export const SubTitle = styled.div`
    font-size: 10px;
    color: ${colors.color_2A69B2};
`;
