import { FormHandles } from '@unform/core';
import { Form } from '@unform/web';
import React, { useRef } from 'react';
import Button from '../../../../components/Button';
import HeaderChildDashboard from '../../../../components/HeaderChildDashboard';
import RegisterButton from '../../../../components/RegisterButton';
import SimpleInput from '../../../../components/SimpleInput';
import {
    Container,
    Content,
    OpenMaloteButton,
    QuickActions,
    QuickActionsContent,
    StatusTableContent,
    StatusTableText,
    SubTitleScanCodeText,
    TableBodyContent,
    TableContent,
    TableHeaderContent,
    TableHeaderText,
    TableLineContent,
    TableLineText,
    TitleScanCodeText,
} from './styles';

const malotes = [
    {
        status: 'Enviado',
        origin: 'Rio de Janeiro/RJ',
        destination: 'Belo Horizonte/MG',
        tag: 'RJ02',
        route: 'Rio de Janeiro / Belo Horizonte',
    },
    {
        status: 'Registrado',
        origin: 'Montes Claros/MG',
        destination: 'Montes Claros/MG',
        tag: 'BHZ02',
        route: 'Belo Horizonte / Montes Claros',
    },
    {
        status: 'Em Registro',
        origin: 'Belo Horizonte/MG',
        destination: 'Ipatinga/MG',
        tag: 'RJ02',
        route: 'Belo Horizonte / Ipatinga',
    },
    {
        status: 'Em Registro',
        origin: 'Belo Horizonte/MG',
        destination: 'Ipatinga/MG',
        tag: 'RJ02',
        route: 'Belo Horizonte / Ipatinga',
    },
];

const Envios: React.FC = () => {
    const formRef = useRef<FormHandles>(null);

    return (
        <Container>
            <HeaderChildDashboard title="" activeMalote={true} />
            <QuickActions>AÇÕES RÁPIDAS</QuickActions>
            <QuickActionsContent>
                <RegisterButton
                    title="REGISTRAR NOVO ENVIO"
                    subTitle="CLIQUE PARA EFETUAR O ENVIO DE NOVOS ITENS"
                    containerStyle={{ width: '244px' }}
                />
                <RegisterButton
                    title="REGISTRAR NOVO RECEBIMENTO"
                    subTitle="CLIQUE PARA EFETUAR O RECEBIMENTO DE NOVOS ITENS"
                />
            </QuickActionsContent>
            <Content>
                <TitleScanCodeText>
                    Escaneie / Digite o espelho do malote
                </TitleScanCodeText>
                <Form
                    ref={formRef}
                    style={{
                        marginTop: '10px',
                        display: 'flex',
                    }}
                    onSubmit={() => {
                        // eslint-disable-next-line no-console
                        console.log('code');
                    }}
                >
                    <SimpleInput
                        name="code"
                        containerStyle={{
                            height: '42px',
                            margin: '0px 30px 0 0',
                        }}
                    />
                    <Button title="REGISTRAR" style={{ height: '42px' }} />
                </Form>
                <SubTitleScanCodeText>
                    Sucesso malote encontrado na base de registro
                </SubTitleScanCodeText>

                <TableContent>
                    <TableHeaderContent>
                        <TableHeaderText style={{ marginLeft: '50px', flex: '0.5' }}>
                            MALOTE
                        </TableHeaderText>
                        <TableHeaderText>LOCALIDADE DE ORIGEM</TableHeaderText>
                        <TableHeaderText>LOCALIDADE DESTINO</TableHeaderText>
                        <TableHeaderText>
                            ESPELHO IDENTIFICADO MALOTE
                        </TableHeaderText>
                        <TableHeaderText>ROTA</TableHeaderText>
                        <TableHeaderText />
                    </TableHeaderContent>
                    <TableBodyContent>
                        {malotes.map((malote, index) => (
                            <TableLineContent key={index}>
                                <TableLineText
                                    style={{ marginLeft: '50px', flex: '0.5' }}
                                >
                                    <StatusTableContent>
                                        <StatusTableText>
                                            {malote.status}
                                        </StatusTableText>
                                    </StatusTableContent>
                                </TableLineText>
                                <TableLineText>{malote.origin}</TableLineText>
                                <TableLineText>{malote.destination}</TableLineText>
                                <TableLineText>{malote.tag}</TableLineText>
                                <TableLineText>{malote.route}</TableLineText>
                                <TableLineText>
                                    <OpenMaloteButton>Abrir Malote</OpenMaloteButton>
                                </TableLineText>
                            </TableLineContent>
                        ))}
                    </TableBodyContent>
                </TableContent>
            </Content>
            <Content>
                <TitleScanCodeText>
                    Escaneie / Digite a etiqueta dos itens que serão enviados neste
                    malote
                </TitleScanCodeText>
                <Form
                    ref={formRef}
                    style={{
                        marginTop: '10px',
                        display: 'flex',
                    }}
                    onSubmit={() => {
                        // eslint-disable-next-line no-console
                        console.log('code');
                    }}
                >
                    <SimpleInput
                        name="code"
                        containerStyle={{
                            width: '670px',
                            height: '42px',
                            margin: '0px 30px 0 0',
                        }}
                    />
                    <Button
                        title="Registrar Envio"
                        style={{ height: '42px', width: '155px' }}
                        typeButton="light"
                        disabled
                    />
                </Form>
            </Content>
        </Container>
    );
};

export default Envios;
