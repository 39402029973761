export interface Tab {
    name: string;
    link: string;
    active: boolean;
}

export const activeTab = (activeName: string, tabs: Array<Tab>): Array<Tab> => {
    const newTabs = tabs.map(tab => {
        return {
            ...tab,
            active: false,
        };
    });

    const findIndex = newTabs.findIndex(item => item.name === activeName);

    newTabs[findIndex] = {
        ...newTabs[findIndex],
        active: true,
    };

    return newTabs;
};
