import React from 'react';
import { Redirect, Route as RouteReactRouterDom, Switch } from 'react-router-dom';
import Sidebar from '../../components/Sidebar';
import Route from '../../routes/Route';
import DeliveryDashboard from './DeliveryRoutes/index.routes';
import MaloteDashboard from './MaloteRoutes/index.routes';
import MensageriaDashboard from './MensageriaRoutes/index.routes';
import SettingsDashboard from './SettingsRoutes/index.routes';

import { Container } from './styles';

const Dashboard: React.FC = () => {
    return (
        <Container>
            <Sidebar />
            <Switch>
                <RouteReactRouterDom exact path="/dashboard">
                    <Redirect to="/dashboard/malote" />
                </RouteReactRouterDom>
                <Route
                    path="/dashboard/malote"
                    component={MaloteDashboard}
                    isPrivate
                />
                <Route
                    path="/dashboard/mensageria"
                    component={MensageriaDashboard}
                    isPrivate
                />
                <Route
                    path="/dashboard/delivery"
                    component={DeliveryDashboard}
                    isPrivate
                />
                <Route
                    path="/dashboard/settings"
                    component={SettingsDashboard}
                    isPrivate
                />
            </Switch>
        </Container>
    );
};

export default Dashboard;
