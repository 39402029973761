import styled, { css } from 'styled-components';
import colors from '../../../styles/colors';

interface Card {
    active?: boolean;
}

export const Container = styled.div<Card>`
    padding: 0 18px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    max-width: 182px;
    height: 44px;
    border-radius: 7px;
    background-color: ${colors.color_CBCBCB};
    border: 1px solid ${colors.color_EAEAEA};

    ${({ active }) =>
        active &&
        css`
            background-color: ${colors.color_3F577B};
        `}
`;

export const Title = styled.p`
    font-size: 15px;
    color: ${colors.color_FFFFFF};
`;

export const Icon = styled.img`
    width: 30px;
    height: 30px;
`;
