import styled from 'styled-components';
import colors from '../../styles/colors';

export const Container = styled.div`
    position: relative;
    margin-top: 95px;
    height: calc(100% - 95px);
    width: 100%;
    background-color: ${colors.color_FFFFFF};

    overflow-y: auto;
    overflow-x: hidden;

    ::-webkit-scrollbar {
        width: 7px;
        height: 7px;
    }

    ::-webkit-scrollbar-thumb {
        background: ${colors.color_3F577B};
        border-radius: 5px;
        transition: 0.5s;
    }

    ::-webkit-scrollbar-track {
        background: ${colors.color_F5F6F8};
        border: 1px solid ${colors.color_BCE0FD};
        border-radius: 5px;
    }
`;

export const Content = styled.div`
    position: absolute;
    width: calc(100% - 15px);
    min-height: calc(100% - 25px);
    height: calc(100% - 25px);
    background-color: black;
    border-top-left-radius: 40px;
    background-color: ${colors.color_F5F6F8};
    padding: 25px 15px 0 15px;
    overflow-y: scroll;

    ::-webkit-scrollbar {
        width: 7px;
        height: 7px;
    }

    ::-webkit-scrollbar-thumb {
        /* background: ${colors.color_3F577B}; */
        border-radius: 5px;
        transition: 0.5s;
    }

    ::-webkit-scrollbar-track {
        background: ${colors.color_F5F6F8};
        /* border: 1px solid ${colors.color_BCE0FD}; */
        /* border-radius: 5px; */
    }
`;
