import { FormHandles } from '@unform/core';
import { Form } from '@unform/web';
import React, { useRef } from 'react';
import Button from '../../../../components/Button';
import Input from '../../../../components/Input';
import {
    Container,
    SubTitle,
    SubTitleContent,
    Title,
    UnderlinedTitle,
} from './styles';

const Registro: React.FC = () => {
    const formRef = useRef<FormHandles>(null);

    return (
        <Container>
            <Title>Registro de objetivos para envio</Title>
            <UnderlinedTitle />
            <SubTitleContent>
                <SubTitle>
                    Através desta opção poderá pré registrar os seus objetivos de
                    envio, adicionando ao mesmo uma etiqueta de rastreamento Mande
                    Seguro{' '}
                </SubTitle>
                <SubTitle>
                    Siga os passos que iremos ajudar a registrar o objeto.
                </SubTitle>
            </SubTitleContent>

            <Form
                ref={formRef}
                style={{
                    marginTop: '42px',
                    display: 'flex',
                    gap: '20px',
                    alignItems: 'flex-end',
                }}
                onSubmit={() => {
                    // eslint-disable-next-line no-console
                    console.log('code');
                }}
            >
                <Input
                    title="Etiqueta de Registro*"
                    name="code"
                    placeholder="Digite ou escaneie a etiqueta"
                    containerStyle={{
                        width: '550px',
                    }}
                />
                <Button title="AVANÇAR" style={{ width: '145px', height: '42px' }} />
            </Form>
            <SubTitle style={{ marginTop: '45px' }}>
                Para registrar um objeto é necessário adicionar uma etiqueta Mande
                Seguro ao mesmo e após isto informa-la no momento do registro.
            </SubTitle>
            <SubTitle>
                Informe a etiqueta Mande Seguro que será anexada junto ao objeto
            </SubTitle>

            <SubTitle style={{ marginTop: '45px' }}>
                Após digitar a etiqueta clique no botão avançar, estaremos
                direcionando você para a utilização fos nossos serviços.
            </SubTitle>
            <SubTitle>
                **A etiqueta e fornecida pela equipe Mande Seguro e é composta por
                letrar e números
            </SubTitle>
        </Container>
    );
};

export default Registro;
