import styled, { css } from 'styled-components';
import colors from '../../styles/colors';

interface ItemMenuProps {
    selected?: boolean;
}

export const Container = styled.div`
    margin-top: 79px;

    height: calc(100% - 2px);
    background-color: ${colors.color_FFFFFF};
    border: 1px solid ${colors.color_D5E3D6};

    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;

    :hover {
        width: 300px;
        div {
            justify-content: space-between;
        }

        transition: 0.3s;
    }

    :not(:hover) {
        width: 100px;
        transition: 0.3s;

        div span {
            display: none;
        }
    }

    div + div {
        margin-top: 8px;
    }
`;

export const ItemMenu = styled.div<ItemMenuProps>`
    width: 100%;
    height: 80px;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;

    cursor: pointer;

    span {
        color: ${colors.color_3F577B};
        font-weight: 600;
        font-size: 16px;
        padding-right: 8px;
    }

    img {
        height: 60px;
        width: 60px;
        padding: 8px 8px 8px 16px;
    }

    ${props =>
        props.selected &&
        css`
            background-color: ${colors.color_3F577B};
            span {
                color: ${colors.color_FFFFFF};
            }

            div {
                display: inline;
            }
        `}
`;

export const MarkItemMenuSelected = styled.div`
    display: none;
    left: 0;
    width: 5px;
    height: 100%;
    position: absolute;
    background-color: ${colors.color_4BB493};
    border-radius: 0 5px 5px 0;
`;
