import React from 'react';
import Input from '../../../../../components/Input';
import Select from '../../../../../components/Select';

import {
    CompanyDataContent,
    Container,
    InputsContent,
    SubTitle,
    SubTitleContent,
    Title,
    UnderlinedTitle,
} from './styles';

interface Company {
    fantasy_name: string;
    company_name: string;
    cnpj: string;
    state_registration: string;
    municipal_registration: string;
    phone_number: string;
    cellphone_number: string;
    email: string;
    status: string;
}

const Company: React.FC = () => {
    return (
        <Container>
            <Title>Digite os dados a baixo</Title>
            <UnderlinedTitle />
            <SubTitleContent>
                <SubTitle>DADOS DA EMPRESA</SubTitle>
            </SubTitleContent>
            <CompanyDataContent>
                <InputsContent>
                    <Input
                        title="Name fantasia*"
                        name="company.fantasy_name"
                        placeholder="Digite o nome fantasia"
                    />

                    <Input
                        title="Razão social*"
                        name="company.company_name"
                        placeholder="Digite a razão social"
                    />
                    <Input
                        title="CNPJ*"
                        name="company.cnpj"
                        placeholder="00.000.000/0000-00"
                        containerStyle={{ width: '200px' }}
                    />
                    <Input
                        title="Inscrição estadual*"
                        name="company.state_registration"
                        placeholder="Digite a inscrição estadual"
                    />
                    <Input
                        title="Inscrição municipal*"
                        name="company.municipal_registration"
                        placeholder="Digite a inscrição municipal"
                    />
                    <Input
                        title="Tel*"
                        name="company.phone_number"
                        placeholder="(00) 00000-0000"
                        containerStyle={{ width: '175px' }}
                    />
                    <Input
                        title="Contato*"
                        name="company.cellphone_number"
                        placeholder="(00) 00000-0000"
                        containerStyle={{ width: '175px' }}
                    />
                    <Input
                        title="E-mail*"
                        name="company.email"
                        placeholder="Digite o seu E-mail"
                    />
                    <Select
                        title="Status*"
                        name="company.status"
                        placeholder="Selecione"
                        containerStyle={{ width: '169px' }}
                        options={[
                            {
                                title: 'Ativo',
                                value: 'Ativo',
                            },
                            { title: 'Inativo', value: 'Inativo' },
                            { title: 'Bloqueado', value: 'Bloqueado' },
                        ]}
                    />
                </InputsContent>
            </CompanyDataContent>

            <SubTitleContent>
                <SubTitle>ENDEREÇO DA EMPRESA</SubTitle>
            </SubTitleContent>
            <CompanyDataContent>
                <InputsContent>
                    <Input
                        title="CEP"
                        name="address.cep"
                        placeholder="Digite o seu CEP"
                        containerStyle={{ width: '200px' }}
                    />

                    <Input
                        title="Rua/Av"
                        name="address.street"
                        placeholder="Digite o nome da rua ou avenida"
                        containerStyle={{ width: '712px' }}
                    />
                    <Input
                        title="Número"
                        name="address.number"
                        placeholder="Número"
                        containerStyle={{ width: '200px' }}
                    />
                    <Input
                        title="Complemento"
                        name="address.complement"
                        placeholder="Complemento"
                        containerStyle={{ width: '200px' }}
                    />
                    <Input
                        title="Estado"
                        name="address.state"
                        placeholder="Selecione o estado"
                        containerStyle={{ width: '200px' }}
                    />
                    <Input
                        title="Cidade"
                        name="address.city"
                        placeholder="Selecione a cidade"
                    />
                    <Input
                        title="Bairro"
                        name="address.district"
                        placeholder="Digite o bairro"
                    />
                </InputsContent>
            </CompanyDataContent>
        </Container>
    );
};

export default Company;
