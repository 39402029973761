import styled from 'styled-components';
import colors from '../../../../styles/colors';

export const Container = styled.div`
    display: flex;
    flex-direction: column;
    width: 95%;
    height: 100%;

    overflow-x: auto;
    overflow-y: hidden;

    ::-webkit-scrollbar {
        width: 7px;
        height: 7px;
    }

    ::-webkit-scrollbar-thumb {
        background: ${colors.color_3F577B};
        border-radius: 5px;
        transition: 0.5s;
    }

    ::-webkit-scrollbar-track {
        background: ${colors.color_F5F6F8};
        border: 1px solid ${colors.color_BCE0FD};
        border-radius: 5px;
    }
`;

export const TableContent = styled.table`
    margin: 25px 0;
    width: 100%;
    max-height: 332px;
    height: 100%;
    background-color: ${colors.color_FFFFFF};
    border: 1px solid ${colors.color_D5E3D6};
    border-radius: 4px;
    box-shadow: 0px 3px 6px #00000029;
`;

export const TableTitle = styled.p`
    position: absolute;
    margin: -10px 0 -10px 13px;
    padding: 0 5px;
    font-size: 13px;
    color: ${colors.color_000000};
    background: linear-gradient(${colors.color_F5F6F8}, ${colors.color_FFFFFF});
    z-index: 1;
`;

export const TableHeaderContent = styled.thead`
    display: flex;
    margin: 20px 25px 0 20px;
    position: sticky;
    background-color: ${colors.color_FFFFFF};
    display: flex;
    align-items: center;
    z-index: 10;
`;

export const TableHeaderText = styled.tr`
    flex: 1;
    font-size: 14px;
    color: ${colors.color_484848};
`;

export const TableBodyContent = styled.tbody`
    margin-top: 20px;
    display: flex;
    flex-direction: column;
    height: calc(100% - 64px - 25px);
    width: 100%;
    overflow-y: scroll;

    overflow-x: auto;
    overflow-y: auto;

    ::-webkit-scrollbar {
        width: 7px;
        height: 7px;
    }

    ::-webkit-scrollbar-thumb {
        background: ${colors.color_3F577B};
        border-radius: 5px;
        transition: 0.5s;
    }

    ::-webkit-scrollbar-track {
        background: ${colors.color_F5F6F8};
        border: 1px solid ${colors.color_BCE0FD};
        border-radius: 5px;
    }
`;

export const TableLineContent = styled.tr`
    display: flex;
    margin: 0 20px;
    display: flex;
    width: calc(100% - 2 * 20px);
    min-height: 64px;
    align-items: center;
    border: 1px solid ${colors.color_D5E3D6};
    border-radius: 4px;
    margin-bottom: 10px;
`;

export const TableLineText = styled.td`
    flex: 1;
    font-size: 16px;
    color: ${colors.color_000000};
`;

export const IconContent = styled.div`
    flex: 0.5;
    display: flex;
    justify-content: flex-end;
`;

export const Icon = styled.img`
    margin-right: 20px;
    align-items: flex-end;
    width: 15px;
    height: 15px;
`;
