export default {
    color_FFFFFF: '#FFFFFF',
    color_000000: '#000000',
    color_D5E3D6: '#D5E3D6',
    color_3F577B: '#3F577B',
    color_4BB493: '#4BB493',
    color_F5F6F8: '#F5F6F8',
    color_BCE0FD: '#BCE0FD',
    color_EEF9F6: '#EEF9F6',
    color_8F9198: '#8F9198',
    color_CBCBCB: '#CBCBCB',
    color_EAEAEA: '#EAEAEA',
    color_707070: '#707070',
    color_4E5358: '#4E5358',
    color_FAFAFA: '#FAFAFA',
    color_AFAFAF: '#AFAFAF',
    color_D9E8D9: '#D9E8D9',
    color_6C63FF: '#6C63FF',
    color_D9DEE5: '#D9DEE5',
    color_2A69B2: '#2A69B2',
    color_021744: '#021744',
    color_606060: '#606060',
    color_1976D2: '#1976D2',
    color_484848: '#484848',
    color_172B4D: '#172B4D',
    color_EBEBEB: '#EBEBEB',
    color_838FAE: '#838FAE',
};
