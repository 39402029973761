import React, { CSSProperties, useEffect, useRef } from 'react';
import { useField } from '@unform/core';

import { FiAlertCircle } from 'react-icons/fi';
import colors from '../../styles/colors';
import { Container, InputContainer, Title, Error } from './styles';

interface InputProps {
    title: string;
    name: string;
    placeholder: string;

    type?: 'text' | 'number' | 'password' | 'email';
    containerStyle?: CSSProperties;
}

const Input: React.FC<InputProps> = props => {
    const inputRef = useRef<HTMLInputElement>(null);
    const { fieldName, defaultValue, error, registerField } = useField(props.name);

    useEffect(() => {
        registerField({
            name: fieldName,
            ref: inputRef.current,
            path: 'value',
        });
    }, [fieldName, registerField]);

    return (
        <Container isErrored={!!error} style={{ ...props.containerStyle }}>
            <div
                style={{
                    display: 'flex',
                }}
            >
                <Title
                    style={{
                        backgroundColor: `${
                            props.containerStyle?.backgroundColor ||
                            colors.color_FFFFFF
                        }`,
                    }}
                >
                    {props.title}
                </Title>
            </div>
            <div
                style={{
                    display: 'flex',
                    width: '100%',
                    height: props.containerStyle?.height || '44px',
                }}
            >
                <InputContainer
                    placeholder={props.placeholder}
                    type={props.type || 'text'}
                    defaultValue={defaultValue}
                    ref={inputRef}
                />
                {error && (
                    <Error title={error}>
                        <FiAlertCircle color="#c53030" size={20} />
                    </Error>
                )}
            </div>
        </Container>
    );
};

export default Input;
