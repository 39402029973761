import styled from 'styled-components';
import colors from '../../../styles/colors';

export const Title = styled.p`
    font-size: 40px;
    color: ${colors.color_3F577B};
`;

export const ForgotPasswordContent = styled.div`
    margin-top: 10px;
    display: flex;
    justify-content: flex-end;
`;

export const ForgotPassword = styled.p`
    text-decoration: underline;
    color: ${colors.color_3F577B};
    cursor: pointer;

    &:hover {
        opacity: 0.9;
    }
`;
