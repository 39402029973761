import React, { useCallback, useRef } from 'react';
import { Form } from '@unform/web';
import { FormHandles } from '@unform/core';
import HeaderChildDashboard from '../../../../components/HeaderChildDashboard';
import {
    Body,
    ClientDataContent,
    Container,
    Icon,
    InputsContent,
    SubmitContent,
    TableBodyContent,
    TableContent,
    TableHeaderContent,
    TableHeaderText,
    TableLineContent,
    TableLineText,
    TableTitle,
} from './styles';

import OptionsIcon from '../../../../assets/svgs/icons/options.svg';
import Select from '../../../../components/Select';
import colors from '../../../../styles/colors';
import Input from '../../../../components/Input';
import Button from '../../../../components/Button';

const clientRoutes = [
    {
        company_name: 'ArcelorMittal',
        origin: 'Belo Horizonte/MG',
        destination: 'Ipatinga/MG',
        created_at: '22/01/2020',
    },
    {
        company_name: 'ArcelorMittal',
        origin: 'Belo Horizonte/MG',
        destination: 'Ipatinga/MG',
        created_at: '22/01/2020',
    },
    {
        company_name: 'ArcelorMittal',
        origin: 'Belo Horizonte/MG',
        destination: 'Ipatinga/MG',
        created_at: '22/01/2020',
    },
    {
        company_name: 'ArcelorMittal',
        origin: 'Belo Horizonte/MG',
        destination: 'Ipatinga/MG',
        created_at: '22/01/2020',
    },
];

const RouteRegistration: React.FC = () => {
    const formRef = useRef<FormHandles>(null);

    const handleSubmit = useCallback(async () => {
        // eslint-disable-next-line no-console
        console.log('select client');
    }, []);

    return (
        <Container>
            <HeaderChildDashboard title="CADASTRO DE ROTAS" />

            <Body>
                <Form ref={formRef} onSubmit={handleSubmit}>
                    <Select
                        title="Cliente"
                        name="client.id"
                        placeholder="Selecione"
                        options={[
                            {
                                title: 'AerlorMittal',
                                value: 'AerlorMittal',
                            },
                        ]}
                        containerStyle={{ backgroundColor: colors.color_F5F6F8 }}
                        style={{
                            backgroundColor: colors.color_F5F6F8,
                            border: `1px solid ${colors.color_AFAFAF}`,
                        }}
                    />

                    <TableTitle>DADOS DA ROTAS</TableTitle>
                    <ClientDataContent>
                        <InputsContent>
                            <Input
                                title="Origem*"
                                name="route.fantasy_name"
                                placeholder="Digite a origem"
                            />

                            <Input
                                title="Destino*"
                                name="route.company_name"
                                placeholder="Digite o Destino"
                            />
                            <Input
                                title="Data de ativação*"
                                name="route.cnpj"
                                placeholder="Data de ativação"
                            />
                            <Input
                                title="Responsável*"
                                name="route.state_registration"
                                placeholder="Responsavel"
                            />
                            <Input
                                title="Contato*"
                                name="route.municipal_registration"
                                placeholder="Contato"
                            />
                            <Input
                                title="Empresa*"
                                name="route.phone_number"
                                placeholder="Empresa"
                            />
                        </InputsContent>
                        <SubmitContent>
                            <Button title="Cancelar" typeButton="light" />
                            <Button title="Salvar" type="submit" />
                        </SubmitContent>
                    </ClientDataContent>
                </Form>

                <TableTitle>ROTAS CADASTRADAS POR CLIENTE</TableTitle>
                <TableContent>
                    <TableHeaderContent>
                        <TableHeaderText
                            style={{ marginLeft: '150px', minWidth: '230px' }}
                        >
                            RAZÃO SOCIAL
                        </TableHeaderText>
                        <TableHeaderText>CNPJ</TableHeaderText>
                        <TableHeaderText>RESPONSAVEL</TableHeaderText>
                        <TableHeaderText>DATA DE CRIAÇÃO</TableHeaderText>
                    </TableHeaderContent>
                    <TableBodyContent>
                        {clientRoutes.map((clientRoute, index) => (
                            <TableLineContent key={index}>
                                <TableLineText
                                    style={{
                                        marginLeft: '150px',
                                        minWidth: '230px',
                                    }}
                                >
                                    {clientRoute.company_name}
                                </TableLineText>
                                <TableLineText>{clientRoute.origin}</TableLineText>
                                <TableLineText>
                                    {clientRoute.destination}
                                </TableLineText>
                                <TableLineText>
                                    {clientRoute.created_at}
                                </TableLineText>
                                <Icon
                                    src={OptionsIcon}
                                    style={{ marginLeft: 'auto' }}
                                />
                            </TableLineContent>
                        ))}
                    </TableBodyContent>
                </TableContent>
            </Body>
        </Container>
    );
};

export default RouteRegistration;
