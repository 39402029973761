import React, { useEffect, useState } from 'react';

import { useHistory, useLocation } from 'react-router-dom';

import MaloteIcon from '../../assets/svgs/icons/Malote.svg';
import MaloteSelectedIcon from '../../assets/svgs/icons/MaloteSelected.svg';

import MensageriaIcon from '../../assets/svgs/icons/Mensageria.svg';
import MensageriaSelectedIcon from '../../assets/svgs/icons/MensageriaSelected.svg';

import DeliveryIcon from '../../assets/svgs/icons/Delivery.svg';
import DeliverySelectedIcon from '../../assets/svgs/icons/DeliverySelected.svg';

import SettingsIcon from '../../assets/svgs/icons/Settings.svg';
import SettingsSelectedIcon from '../../assets/svgs/icons/SettingsSelected.svg';

import { Container, ItemMenu, MarkItemMenuSelected } from './styles';

const Sidebar: React.FC = () => {
    const history = useHistory();
    const location = useLocation();

    const [itemSelected, setItemSelected] = useState('');

    useEffect(() => {
        const paths = location.pathname.split('/');
        switch (paths[paths.length - 1]) {
            case 'malote':
                setItemSelected('Malote');
                break;
            case 'mensageria':
                setItemSelected('Mensageria');
                break;
            case 'delivery':
                setItemSelected('Entrega');
                break;
            case 'settings':
                setItemSelected('Configurações');
                break;
            default:
                setItemSelected('Malote');
                break;
        }
    }, [location]);

    return (
        <Container>
            <ItemMenu
                selected={itemSelected === 'Malote'}
                onClick={() => {
                    setItemSelected('Malote');
                    history.push(`/dashboard/malote`);
                }}
            >
                <img
                    src={itemSelected === 'Malote' ? MaloteSelectedIcon : MaloteIcon}
                    alt="Icon"
                />
                <span>Malote</span>

                <MarkItemMenuSelected />
            </ItemMenu>
            <ItemMenu
                selected={itemSelected === 'Mensageria'}
                onClick={() => {
                    setItemSelected('Mensageria');
                    history.push(`/dashboard/mensageria`);
                }}
            >
                <img
                    src={
                        itemSelected === 'Mensageria'
                            ? MensageriaSelectedIcon
                            : MensageriaIcon
                    }
                    alt="Icon"
                />
                <span>Mensageria</span>
                <MarkItemMenuSelected />
            </ItemMenu>
            <ItemMenu
                selected={itemSelected === 'Entrega'}
                onClick={() => {
                    setItemSelected('Entrega');
                    history.push(`/dashboard/delivery`);
                }}
            >
                <img
                    src={
                        itemSelected === 'Entrega'
                            ? DeliverySelectedIcon
                            : DeliveryIcon
                    }
                    alt="Icon"
                />
                <span>Entrega</span>
                <MarkItemMenuSelected />
            </ItemMenu>
            <ItemMenu
                selected={itemSelected === 'Configurações'}
                onClick={() => {
                    setItemSelected('Configurações');
                    history.push(`/dashboard/settings`);
                }}
            >
                <img
                    src={
                        itemSelected === 'Configurações'
                            ? SettingsSelectedIcon
                            : SettingsIcon
                    }
                    alt="Icon"
                />
                <span>Configurações</span>
                <MarkItemMenuSelected />
            </ItemMenu>
        </Container>
    );
};

export default Sidebar;
