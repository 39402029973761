import React from 'react';
import HeaderChildDashboard from '../../../../components/HeaderChildDashboard';
import {
    Container,
    Icon,
    IconContent,
    TableBodyContent,
    TableContent,
    TableHeaderContent,
    TableHeaderText,
    TableLineContent,
    TableLineText,
    TableTitle,
} from './styles';

import OptionsIcon from '../../../../assets/svgs/icons/options.svg';

const clients = [
    {
        company_name: 'ArcelorMittal',
        cnpj: '61.037.857/0001-70',
        username: 'Caio Francisco',
        created_at: '14/01/2020',
    },
    {
        company_name: 'ArcelorMittal',
        cnpj: '61.037.857/0001-70',
        username: 'Caio Francisco',
        created_at: '14/01/2020',
    },
    {
        company_name: 'ArcelorMittal',
        cnpj: '61.037.857/0001-70',
        username: 'Caio Francisco',
        created_at: '14/01/2020',
    },
    {
        company_name: 'ArcelorMittal',
        cnpj: '61.037.857/0001-70',
        username: 'Caio Francisco',
        created_at: '14/01/2020',
    },
];

const clientRoutes = [
    {
        company_name: 'ArcelorMittal',
        origin: 'Belo Horizonte/MG',
        destination: 'Ipatinga/MG',
        created_at: '22/01/2020',
    },
    {
        company_name: 'ArcelorMittal',
        origin: 'Belo Horizonte/MG',
        destination: 'Ipatinga/MG',
        created_at: '22/01/2020',
    },
    {
        company_name: 'ArcelorMittal',
        origin: 'Belo Horizonte/MG',
        destination: 'Ipatinga/MG',
        created_at: '22/01/2020',
    },
    {
        company_name: 'ArcelorMittal',
        origin: 'Belo Horizonte/MG',
        destination: 'Ipatinga/MG',
        created_at: '22/01/2020',
    },
];

const ClientSettings: React.FC = () => {
    return (
        <Container>
            <HeaderChildDashboard title="ROTAS E CLIENTES" />
            <TableContent>
                <TableTitle>CLIENTES CADASTRADOS</TableTitle>
                <TableHeaderContent>
                    <TableHeaderText
                        style={{ marginLeft: '150px', minWidth: '230px' }}
                    >
                        RAZÃO SOCIAL
                    </TableHeaderText>
                    <TableHeaderText>CNPJ</TableHeaderText>
                    <TableHeaderText>RESPONSAVEL</TableHeaderText>
                    <TableHeaderText>DATA DE CRIAÇÃO</TableHeaderText>
                    <TableHeaderText style={{ flex: '0.5' }}></TableHeaderText>
                </TableHeaderContent>
                <TableBodyContent>
                    {clients.map((client, index) => (
                        <TableLineContent key={index}>
                            <TableLineText
                                style={{ marginLeft: '150px', minWidth: '230px' }}
                            >
                                {client.company_name}
                            </TableLineText>
                            <TableLineText>{client.cnpj}</TableLineText>
                            <TableLineText>{client.username}</TableLineText>
                            <TableLineText>{client.created_at}</TableLineText>
                            <IconContent>
                                <Icon src={OptionsIcon} />
                            </IconContent>
                        </TableLineContent>
                    ))}
                </TableBodyContent>
            </TableContent>

            <TableContent>
                <TableTitle>ROTAS CADASTRADAS POR CLIENTE</TableTitle>
                <TableHeaderContent>
                    <TableHeaderText
                        style={{ marginLeft: '150px', minWidth: '230px' }}
                    >
                        RAZÃO SOCIAL
                    </TableHeaderText>
                    <TableHeaderText>CNPJ</TableHeaderText>
                    <TableHeaderText>RESPONSAVEL</TableHeaderText>
                    <TableHeaderText>DATA DE CRIAÇÃO</TableHeaderText>
                    <TableHeaderText style={{ flex: '0.5' }}></TableHeaderText>
                </TableHeaderContent>
                <TableBodyContent>
                    {clientRoutes.map((clientRoute, index) => (
                        <TableLineContent key={index}>
                            <TableLineText
                                style={{ marginLeft: '150px', minWidth: '230px' }}
                            >
                                {clientRoute.company_name}
                            </TableLineText>
                            <TableLineText>{clientRoute.origin}</TableLineText>
                            <TableLineText>{clientRoute.destination}</TableLineText>
                            <TableLineText>{clientRoute.created_at}</TableLineText>
                            <IconContent>
                                <Icon src={OptionsIcon} />
                            </IconContent>
                        </TableLineContent>
                    ))}
                </TableBodyContent>
            </TableContent>
        </Container>
    );
};

export default ClientSettings;
