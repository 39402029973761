import styled from 'styled-components';
import colors from '../../../../styles/colors';

export const Container = styled.div`
    width: 100%;
    height: 100%;
`;

export const TypePersonContainer = styled.div`
    margin-top: -25px;
    width: 100%;
    max-width: 300px;
    height: 35px;
    display: flex;
    justify-content: space-between;

    @media (max-width: 1050px) {
        margin-top: 0px;
    }
`;

export const TypeText = styled.p`
    margin-left: 15px;
    font-size: 22px;
    color: ${colors.color_3F577B};
`;

export const TypePersonContent = styled.div`
    display: flex;
    height: 100%;
    align-items: center;
    cursor: pointer;
`;

export const IconTypePerson = styled.img`
    width: 15px;
    height: 15px;
`;

export const TypePersonText = styled.p`
    margin-left: 8px;
    font-size: 15px;
    color: ${colors.color_3F577B};
`;

export const heightContent = 600;
export const Content = styled.div`
    margin-top: 32px;
    display: flex;
    width: 95%;
    height: ${heightContent}px;
    border-radius: 11px;
    box-shadow: 0px 3px 6px #00000029;
    background-color: ${colors.color_FFFFFF};
`;

export const widthImageContent = 250;
export const ImageContent = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    width: ${widthImageContent}px;
    height: 100%;
    border-top-left-radius: 11px;
    border-bottom-left-radius: 11px;
    background-color: ${colors.color_3F577B};
`;

export const ImageProfile = styled.img`
    margin-top: 60px;
    border-radius: 65px;
    background-color: ${colors.color_FFFFFF};
    border: 3px solid ${colors.color_FFFFFF};
    width: 130px;
    height: 130px;
    border-radius: 65px;
`;

export const SubmitContent = styled.div`
    margin: 30px 0;
    display: flex;
    width: 95%;
    justify-content: flex-end;
    gap: 13px;
`;
