import styled, { css } from 'styled-components';
import colors from '../../styles/colors';

interface ContainerProps {
    isErrored: boolean;
}

export const Container = styled.div<ContainerProps>`
    display: flex;
    flex-direction: column;
    width: 350px;

    ${props =>
        props.isErrored &&
        css`
            border-color: #c53030;
        `}
`;

export const Title = styled.p`
    margin: 0 0 -10px 3px;
    padding: 0 5px;
    font-size: 13px;
    z-index: 1;
`;

export const SelectContainer = styled.select`
    padding: 0 20px;
    width: 100%;
    height: 100%;
    border-radius: 3px;
    font-size: 15px;
    border: 1px solid ${colors.color_707070};
`;

export const OptionContainer = styled.option``;
