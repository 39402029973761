import React from 'react';
import HeaderChildDashboard from '../../../../components/HeaderChildDashboard';
import {
    Container,
    Icon,
    IconContent,
    TableBodyContent,
    TableContent,
    TableHeaderContent,
    TableHeaderText,
    TableLineContent,
    TableLineText,
} from './styles';

import OptionsIcon from '../../../../assets/svgs/icons/options.svg';

const accounts = [
    {
        name: 'Laura Fonseca Kuch',
        company: 'ITAÚ',
        department: 'Finanaceiro',
        username: 'Laura kuch',
    },
    {
        name: 'Laura Fonseca Kuch dfdfvdf d dfdfdd dffd',
        company: 'ITAÚ',
        department: 'Finanaceiro',
        username: 'Laura kuch',
    },
    {
        name: 'Laura Fonseca Kuch',
        company: 'ITAÚ',
        department: 'Finanaceiro',
        username: 'Laura kuch',
    },
    {
        name: 'Laura Fonseca Kuch',
        company: 'ITAÚ',
        department: 'Finanaceiro',
        username: 'Laura kuch',
    },
    {
        name: 'Laura Fonseca Kuch',
        company: 'ITAÚ',
        department: 'Finanaceiro',
        username: 'Laura kuch',
    },
    {
        name: 'Laura Fonseca Kuch',
        company: 'ITAÚ',
        department: 'Finanaceiro',
        username: 'Laura kuch',
    },
    {
        name: 'Laura Fonseca Kuch',
        company: 'ITAÚ',
        department: 'Finanaceiro',
        username: 'Laura kuch',
    },
    {
        name: 'Laura Fonseca Kuch',
        company: 'ITAÚ',
        department: 'Finanaceiro',
        username: 'Laura kuch',
    },
    {
        name: 'Laura Fonseca Kuch',
        company: 'ITAÚ',
        department: 'Finanaceiro',
        username: 'Laura kuch',
    },
    {
        name: 'Laura Fonseca Kuch',
        company: 'ITAÚ',
        department: 'Finanaceiro',
        username: 'Laura kuch',
    },
    {
        name: 'Laura Fonseca Kuch',
        company: 'ITAÚ',
        department: 'Finanaceiro',
        username: 'Laura kuch',
    },
    {
        name: 'Laura Fonseca Kuch',
        company: 'ITAÚ',
        department: 'Finanaceiro',
        username: 'Laura kuch',
    },
    {
        name: 'Laura Fonseca Kuch',
        company: 'ITAÚ',
        department: 'Finanaceiro',
        username: 'Laura kuch',
    },
    {
        name: 'Laura Fonseca Kuch',
        company: 'ITAÚ',
        department: 'Finanaceiro',
        username: 'Laura kuch',
    },
    {
        name: 'Laura Fonseca Kuch',
        company: 'ITAÚ',
        department: 'Finanaceiro',
        username: 'Laura kuch',
    },
    {
        name: 'Laura Fonseca Kuch',
        company: 'ITAÚ',
        department: 'Finanaceiro',
        username: 'Laura kuch',
    },
    {
        name: 'Laura Fonseca Kuch',
        company: 'ITAÚ',
        department: 'Finanaceiro',
        username: 'Laura kuch',
    },
    {
        name: 'Laura Fonseca Kuch',
        company: 'ITAÚ',
        department: 'Finanaceiro',
        username: 'Laura kuch',
    },
    {
        name: 'Laura Fonseca Kuch',
        company: 'ITAÚ',
        department: 'Finanaceiro',
        username: 'Laura kuch',
    },
    {
        name: 'Laura Fonseca Kuch',
        company: 'ITAÚ',
        department: 'Finanaceiro',
        username: 'Laura kuch',
    },
    {
        name: 'Laura Fonseca Kuch',
        company: 'ITAÚ',
        department: 'Finanaceiro',
        username: 'Laura kuch',
    },
    {
        name: 'Laura Fonseca Kuch',
        company: 'ITAÚ',
        department: 'Finanaceiro',
        username: 'Laura kuch',
    },
    {
        name: 'Laura Fonseca Kuch',
        company: 'ITAÚ',
        department: 'Finanaceiro',
        username: 'Laura kuch',
    },
    {
        name: 'Laura Fonseca Kuch',
        company: 'ITAÚ',
        department: 'Finanaceiro',
        username: 'Laura kuch',
    },
    {
        name: 'Laura Fonseca Kuch',
        company: 'ITAÚ',
        department: 'Finanaceiro',
        username: 'Laura kuch',
    },
    {
        name: 'Laura Fonseca Kuch',
        company: 'ITAÚ',
        department: 'Finanaceiro',
        username: 'Laura kuch',
    },
    {
        name: 'Laura Fonseca Kuch',
        company: 'ITAÚ',
        department: 'Finanaceiro',
        username: 'Laura kuch',
    },
    {
        name: 'Laura Fonseca Kuch',
        company: 'ITAÚ',
        department: 'Finanaceiro',
        username: 'Laura kuch',
    },
    {
        name: 'Laura Fonseca Kuch',
        company: 'ITAÚ',
        department: 'Finanaceiro',
        username: 'Laura kuch',
    },
    {
        name: 'Laura Fonseca Kuch',
        company: 'ITAÚ',
        department: 'Finanaceiro',
        username: 'Laura kuch',
    },
    {
        name: 'Laura Fonseca Kuch',
        company: 'ITAÚ',
        department: 'Finanaceiro',
        username: 'Laura kuch',
    },
    {
        name: 'Laura Fonseca Kuch',
        company: 'ITAÚ',
        department: 'Finanaceiro',
        username: 'Laura kuch',
    },
    {
        name: 'Laura Fonseca Kuch',
        company: 'ITAÚ',
        department: 'Finanaceiro',
        username: 'Laura kuch',
    },
    {
        name: 'Laura Fonseca Kuch',
        company: 'ITAÚ',
        department: 'Finanaceiro',
        username: 'Laura kuch',
    },
    {
        name: 'Laura Fonseca Kuch',
        company: 'ITAÚ',
        department: 'Finanaceiro',
        username: 'Laura kuch',
    },
    {
        name: 'Laura Fonseca Kuch',
        company: 'ITAÚ',
        department: 'Finanaceiro',
        username: 'Laura kuch',
    },
    {
        name: 'Laura Fonseca Kuch',
        company: 'ITAÚ',
        department: 'Finanaceiro',
        username: 'Laura kuch',
    },
    {
        name: 'Laura Fonseca Kuch',
        company: 'ITAÚ',
        department: 'Finanaceiro',
        username: 'Laura kuch',
    },
    {
        name: 'Laura Fonseca Kuch',
        company: 'ITAÚ',
        department: 'Finanaceiro',
        username: 'Laura kuch',
    },
    {
        name: 'Laura Fonseca Kuch',
        company: 'ITAÚ',
        department: 'Finanaceiro',
        username: 'Laura kuch',
    },
    {
        name: 'Laura Fonseca Kuch',
        company: 'ITAÚ',
        department: 'Finanaceiro',
        username: 'Laura kuch',
    },
    {
        name: 'Laura Fonseca Kuch',
        company: 'ITAÚ',
        department: 'Finanaceiro',
        username: 'Laura kuch',
    },
    {
        name: 'Laura Fonseca Kuch',
        company: 'ITAÚ',
        department: 'Finanaceiro',
        username: 'Laura kuch',
    },
    {
        name: 'Laura Fonseca Kuch',
        company: 'ITAÚ',
        department: 'Finanaceiro',
        username: 'Laura kuch',
    },
    {
        name: 'Laura Fonseca Kuch',
        company: 'ITAÚ',
        department: 'Finanaceiro',
        username: 'Laura kuch',
    },
    {
        name: 'Laura Fonseca Kuch',
        company: 'ITAÚ',
        department: 'Finanaceiro',
        username: 'Laura kuch',
    },
    {
        name: 'Laura Fonseca Kuch',
        company: 'ITAÚ',
        department: 'Finanaceiro',
        username: 'Laura kuch',
    },
];

const AccountsSettings: React.FC = () => {
    return (
        <Container>
            <HeaderChildDashboard title="GERENCIAMENTO DE CONTAS" />

            <TableContent>
                <TableHeaderContent>
                    <TableHeaderText style={{ marginLeft: '150px' }}>
                        NOME PESSOAL
                    </TableHeaderText>
                    <TableHeaderText>EMPRESA</TableHeaderText>
                    <TableHeaderText>DEPARTAMETO</TableHeaderText>
                    <TableHeaderText>NOME DE USUARIO</TableHeaderText>
                    <TableHeaderText style={{ flex: '0.5' }}></TableHeaderText>
                </TableHeaderContent>
                <TableBodyContent>
                    {accounts.map((account, index) => (
                        <TableLineContent key={index}>
                            <TableLineText style={{ marginLeft: '150px' }}>
                                {account.name}
                            </TableLineText>
                            <TableLineText>{account.company}</TableLineText>
                            <TableLineText>{account.department}</TableLineText>
                            <TableLineText>{account.username}</TableLineText>
                            <IconContent>
                                <Icon src={OptionsIcon} />
                            </IconContent>
                        </TableLineContent>
                    ))}
                </TableBodyContent>
            </TableContent>
        </Container>
    );
};

export default AccountsSettings;
