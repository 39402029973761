import { FormHandles } from '@unform/core';
import { Form } from '@unform/web';
import React, { useCallback, useRef } from 'react';
import { useHistory } from 'react-router-dom';
import * as Yup from 'yup';

import LogoMandeSeguroWhite from '../../../assets/svgs/LogoMandeSeguroWhite.svg';
import LogoMandeSeguro from '../../../assets/svgs/LogoMandeSeguro.svg';
import Input from '../../../components/Input';
import getValidationErrors from '../../../utils/getValidationErros';

import { useAuth } from '../../../hooks/auth';
import { useToast } from '../../../hooks/toast';

import { ForgotPassword, ForgotPasswordContent, Title } from './styles';
import {
    Body,
    ClientButton,
    ClientText,
    Container,
    Content,
    ContentWallpaper,
    Header,
    HeaderItenContent,
    HeaderItenText,
    LoginButton,
    LoginContent,
    LogoMandeSeguroImg,
    LogoMandeSeguroWhiteContent,
    LogoMandeSeguroWhiteImg,
} from '../styles';

interface SignInFormData {
    email: string;
    password: string;
}

const SignIn: React.FC = () => {
    const formRef = useRef<FormHandles>(null);

    const { signIn } = useAuth();
    const { addToast } = useToast();
    const history = useHistory();

    const handleSubmit = useCallback(
        async (data: SignInFormData) => {
            try {
                formRef.current?.setErrors({});
                const schema = Yup.object().shape({
                    email: Yup.string()
                        .required('E-mail obrigatório')
                        .email('Digite um e-mail válido'),
                    password: Yup.string().required('Senha obrigatória'),
                });

                await schema.validate(data, {
                    abortEarly: false,
                });

                await signIn({
                    email: data.email,
                    password: data.password,
                });

                history.push('/dashboard');

                addToast({
                    type: 'success',
                    title: 'Usuário autenticado com sucesso!',
                });
            } catch (err) {
                if (err instanceof Yup.ValidationError) {
                    const errors = getValidationErrors(err);

                    formRef.current?.setErrors(errors);

                    return;
                }

                addToast({
                    type: 'error',
                    title: 'Erro na autenticação',
                    description:
                        'Ocorreu um erro ao fazer login!\nVerifique as credenciais, por favor!',
                });
            }
        },
        [signIn, addToast, history],
    );

    return (
        <Container>
            <Content>
                <LogoMandeSeguroImg src={LogoMandeSeguro} alt="LogoMandeSeguro" />
                <Body>
                    <LoginContent>
                        <Title>Já sou cliente</Title>
                        <Form
                            ref={formRef}
                            onSubmit={handleSubmit}
                            style={{ width: '100%' }}
                        >
                            <Input
                                title="E-mail"
                                name="email"
                                type="email"
                                placeholder="Digite o e-mail vinculado ao seu acesso"
                                containerStyle={{ marginTop: '30px', width: '100%' }}
                            />
                            <Input
                                title="Senha"
                                name="password"
                                type="password"
                                placeholder="Digite a senha"
                                containerStyle={{ marginTop: '20px', width: '100%' }}
                            />
                            <LoginButton type="submit">ACESSAR CONTA</LoginButton>
                            <ForgotPasswordContent>
                                <ForgotPassword
                                    onClick={() => history.push('/forgotPassword')}
                                >
                                    Esqueci minha senha
                                </ForgotPassword>
                            </ForgotPasswordContent>
                        </Form>
                    </LoginContent>
                </Body>
            </Content>
            <ContentWallpaper>
                <Header>
                    <HeaderItenContent>
                        <HeaderItenText>SOBRE NÓS</HeaderItenText>
                        <HeaderItenText>REGISTRO DE OBJETOS</HeaderItenText>
                        <HeaderItenText>FALE CONOSCO</HeaderItenText>
                    </HeaderItenContent>
                    <ClientButton>
                        <ClientText>SEJA UM CLIENTE</ClientText>
                    </ClientButton>
                </Header>
                <LogoMandeSeguroWhiteContent>
                    <LogoMandeSeguroWhiteImg
                        src={LogoMandeSeguroWhite}
                        alt="LogoMandeSeguro"
                    />
                </LogoMandeSeguroWhiteContent>
            </ContentWallpaper>
        </Container>
    );
};

export default SignIn;
