import styled from 'styled-components';
import colors from '../../styles/colors';

interface ActiveDropdownContent {
    active?: boolean;
}

export const Container = styled.div`
    position: absolute;
    left: 0;
    display: flex;
    align-items: center;
    width: 100%;
    height: 80px;
    background-color: ${colors.color_FFFFFF};
    box-shadow: 0px 3px 6px #00000029;
`;

export const LogoMandeSeguroContent = styled.img`
    margin-left: 23px;
    width: 122px;
    height: 42px;
`;

export const Tabs = styled.div`
    display: flex;
    align-items: center;
    height: 100%;
    margin-left: 50px;
`;

export const UserContent = styled.div`
    margin-left: auto;
    margin-right: 50px;
    display: flex;
    align-items: center;
    height: 100%;
`;

export const NotificationImageContent = styled.img`
    width: 24px;
    height: 29px;
    margin-right: 40px;

    @media (max-width: 1370px) {
        width: 22px;
        height: 27px;
        margin-right: 20px;

        @media (max-width: 1175px) {
            width: 20px;
            height: 25px;
        }
    }
`;

export const UserProfileContent = styled.div`
    display: flex;
    height: 100%;
    align-items: center;
    cursor: pointer;
`;

export const ProfileContent = styled.div`
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    justify-content: center;
    height: 100%;
`;

export const TextUserName = styled.p`
    font-size: 16px;
    color: ${colors.color_3F577B};

    @media (max-width: 1370px) {
        font-size: 14px;

        @media (max-width: 1175px) {
            font-size: 12px;
        }
    }
`;

export const TextUserProfession = styled.p`
    font-size: 14px;
    color: ${colors.color_8F9198};

    @media (max-width: 1370px) {
        font-size: 12px;

        @media (max-width: 1175px) {
            font-size: 10px;
        }
    }
`;

export const ImageProfileContent = styled.img`
    margin-left: 25px;
    width: 48px;
    height: 48px;
    border-radius: 24px;
    border: 1px solid ${colors.color_EEF9F6};
    z-index: 2;

    @media (max-width: 1370px) {
        width: 38px;
        height: 38px;

        @media (max-width: 1175px) {
            width: 30px;
            height: 30px;

            @media (max-width: 1000px) {
                display: none;
            }
        }
    }
`;

export const ArrowContent = styled.img<ActiveDropdownContent>`
    margin-left: 15px;
    width: 8px;
    height: 8px;

    ${({ active }) =>
        active &&
        `
        transform: rotate(180deg);
    `}
`;

export const PositionDropdownContent = styled.div`
    position: relative;
    background-color: red;
    /* padding-left: -150px; */
`;

export const DropdownContent = styled.div<ActiveDropdownContent>`
    display: none;
    position: absolute;
    top: 22px;
    left: -195px;
    width: 195px;
    background-color: ${colors.color_FFFFFF};
    box-shadow: 0px 3px 6px #00000029;
    z-index: 1;

    a {
        text-decoration: none;
    }

    ${({ active }) =>
        active &&
        `
            display: flex;
            flex-direction: column;
    `};
`;

export const TextDropdownContent = styled.div`
    display: flex;
    width: 100%;
    height: 41px;
    justify-content: flex-end;
    align-items: center;

    &:hover {
        background-color: ${colors.color_EEF9F6};
    }
`;

export const TextDropdown = styled.p`
    font-size: 14px;
    color: ${colors.color_8F9198};
    margin-right: 12px;
`;

export const LogoCompanyContent = styled.img`
    margin-left: 30px;
    width: 125px;
    height: 34px;

    @media (max-width: 1370px) {
        width: 100px;
        height: 30px;

        @media (max-width: 1175px) {
            width: 80px;
            height: 25px;

            @media (max-width: 1000px) {
                display: none;
            }
        }
    }
`;
