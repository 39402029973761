import React from 'react';
import { Tab } from '../../../pages/Dashboard/common';

import { Container, Content, Title } from './styles';

interface ItemHeaderProps extends Tab {
    changeTab(name: string): void;
}

const ItemHeader: React.FC<ItemHeaderProps> = props => {
    return (
        <Container>
            <Content
                active={props.active}
                onClick={() => props.changeTab(props.name)}
            >
                <Title active={props.active}>{props.name}</Title>
            </Content>
        </Container>
    );
};

export default ItemHeader;
