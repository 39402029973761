import React, {
    ButtonHTMLAttributes,
    CSSProperties,
    useEffect,
    useRef,
} from 'react';
import { useField } from '@unform/core';

import { FiAlertCircle } from 'react-icons/fi';
import { Container, InputContainer, Error } from './styles';

interface InputProps extends ButtonHTMLAttributes<HTMLInputElement> {
    name: string;

    containerStyle?: CSSProperties;
}

const SimpleInput: React.FC<InputProps> = ({ name, containerStyle, ...rest }) => {
    const inputRef = useRef<HTMLInputElement>(null);
    const { fieldName, defaultValue, error, registerField } = useField(name);

    useEffect(() => {
        registerField({
            name: fieldName,
            ref: inputRef.current,
            path: 'value',
        });
    }, [fieldName, registerField]);

    return (
        <Container isErrored={!!error} style={{ ...containerStyle }}>
            <InputContainer defaultValue={defaultValue} ref={inputRef} {...rest} />
            {error && (
                <Error title={error}>
                    <FiAlertCircle color="#c53030" size={20} />
                </Error>
            )}
        </Container>
    );
};

export default SimpleInput;
