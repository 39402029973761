import styled from 'styled-components';
import colors from '../../styles/colors';
import WallpaperLogin from '../../assets/svgs/WallpaperLogin.svg';

export const Container = styled.div`
    display: flex;
    width: 100%;
    height: 100%;
`;

export const Content = styled.div`
    margin: 0 10px;
    display: flex;
    flex-direction: column;
    width: 50%;
    height: 100%;
`;

export const LogoMandeSeguroImg = styled.img`
    margin: 100px auto 0 100px;
    top: 10px;
    width: 283px;
    height: 97px;
`;

export const Body = styled.div`
    display: flex;
    width: 100%;
    height: 100%;
    justify-content: center;
`;

export const LoginContent = styled.div`
    display: flex;
    align-self: center;
    flex-direction: column;
    align-items: center;
    width: 100%;
    max-width: 586px;
    height: 50%;
`;

export const ContentWallpaper = styled.div`
    position: relative;
    display: flex;
    flex-direction: column;
    width: 50%;
    height: 100%;
    background-color: ${colors.color_021744};
    background-image: url(${WallpaperLogin});
    background-position-x: center;
    background-repeat: no-repeat;
`;

export const LoginButton = styled.button`
    margin-top: 30px;
    border: none;
    width: 100%;
    min-height: 51px;
    font-size: 18px;
    font-weight: bold;
    color: ${colors.color_FFFFFF};
    background-color: ${colors.color_3F577B};
    border-radius: 4px;

    &:hover {
        opacity: 0.9;
    }
`;

export const Header = styled.div`
    margin: 82px 82px 0 0;
    display: flex;
    width: calc(100% - 82px);
    height: 45px;
    justify-content: flex-end;
`;

export const HeaderItenContent = styled.div`
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    z-index: 1;
`;

export const HeaderItenText = styled.p`
    font-size: 16px;
    font-weight: bold;
    color: ${colors.color_FFFFFF};
    margin-right: 40px;
    cursor: pointer;
`;

export const ClientButton = styled.div`
    width: 197px;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 23px;
    background-color: ${colors.color_FFFFFF};
    cursor: pointer;
    z-index: 1;
`;

export const ClientText = styled.p`
    font-size: 16px;
    color: ${colors.color_3F577B};
`;

export const LogoMandeSeguroWhiteContent = styled.div`
    margin: 0 70px;
    width: calc(100% - 2 * 70px);
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
`;

export const LogoMandeSeguroWhiteImg = styled.img`
    width: 100%;
    height: 100%;
    max-width: 583px;
    max-height: 293px;
`;

export const WallpaperLoginImg = styled.img`
    position: absolute;
    width: 100%;
    height: 100%;
`;
