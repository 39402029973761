import { FormHandles } from '@unform/core';
import { Form } from '@unform/web';
import React, { useCallback, useRef, useState } from 'react';
import { useHistory } from 'react-router-dom';
import * as Yup from 'yup';

import LogoMandeSeguroWhite from '../../../assets/svgs/LogoMandeSeguroWhite.svg';
import LogoMandeSeguro from '../../../assets/svgs/LogoMandeSeguro.svg';
import LogoForgotPassword from '../../../assets/svgs/ForgotPassword.svg';
import LogoSendEmail from '../../../assets/svgs/SendEmail.svg';
import Input from '../../../components/Input';
import getValidationErrors from '../../../utils/getValidationErros';

import {
    Title,
    TitleContent,
    HeaderTitleContent,
    LogoForgotPasswordImg,
    LogoSendEmailImg,
    SendEmailContent,
    TitleSendEmail,
    DescriptionSendEmail,
    LogoMandeSeguroSendEmailImg,
    ResendEmailButton,
} from './styles';

import { useToast } from '../../../hooks/toast';
import api from '../../../services/api';
import {
    Body,
    ClientButton,
    ClientText,
    Container,
    Content,
    ContentWallpaper,
    Header,
    HeaderItenContent,
    HeaderItenText,
    LoginButton,
    LoginContent,
    LogoMandeSeguroImg,
    LogoMandeSeguroWhiteContent,
    LogoMandeSeguroWhiteImg,
} from '../styles';

interface ForgotPasswordFormData {
    email: string;
}

const ForgotPassword: React.FC = () => {
    const formRef = useRef<FormHandles>(null);
    const [screenSendEmail, setScreenSendEmail] = useState(false);
    const [resendEmail, setResendEmail] = useState('');

    const history = useHistory();
    const { addToast } = useToast();

    const handleSubmit = useCallback(
        async (data: ForgotPasswordFormData) => {
            try {
                formRef.current?.setErrors({});
                const schema = Yup.object().shape({
                    email: Yup.string()
                        .required('E-mail obrigatório')
                        .email('Digite um e-mail válido'),
                });

                await schema.validate(data, {
                    abortEarly: false,
                });

                await api.post('password/forgot', {
                    email: data.email,
                });

                setScreenSendEmail(true);
                setResendEmail(data.email);
            } catch (err) {
                if (err instanceof Yup.ValidationError) {
                    const errors = getValidationErrors(err);

                    formRef.current?.setErrors(errors);

                    return;
                }

                addToast({
                    type: 'error',
                    title: 'Erro no envio do e-mail',
                    description:
                        'Ocorreu um erro ao tentar enviar o e-mail, por favor tente novamente',
                });
            }
        },
        [addToast, setResendEmail],
    );

    const handleResendEmail = useCallback(async () => {
        try {
            await api.post('password/forgot', {
                email: resendEmail,
            });
        } catch (err) {
            addToast({
                type: 'error',
                title: 'Erro no envio do e-mail',
                description:
                    'Ocorreu um erro ao tentar enviar o e-mail, por favor tente novamente',
            });
        }
    }, [addToast, resendEmail]);

    return (
        <Form
            ref={formRef}
            onSubmit={handleSubmit}
            style={{
                width: '100%',
                height: '100%',
            }}
        >
            <Container>
                <Content>
                    {!screenSendEmail ? (
                        <>
                            <LogoMandeSeguroImg
                                onClick={() => history.push(`/`)}
                                src={LogoMandeSeguro}
                                alt="LogoMandeSeguro"
                                style={{ cursor: 'pointer' }}
                            />
                            <Body>
                                <LoginContent>
                                    <HeaderTitleContent>
                                        <TitleContent>
                                            <Title>Esqueceu a senha?</Title>
                                            <Title>Fique tranquilo!</Title>
                                        </TitleContent>
                                        <LogoForgotPasswordImg
                                            src={LogoForgotPassword}
                                            alt="LogoForgotPassword"
                                        />
                                    </HeaderTitleContent>
                                    <Input
                                        title="E-mail"
                                        name="email"
                                        type="email"
                                        placeholder="Digite o e-mail vinculado ao seu acesso"
                                        containerStyle={{
                                            marginTop: '30px',
                                            width: '100%',
                                        }}
                                    />
                                    <LoginButton type="submit">
                                        REDEFINIR SENHA
                                    </LoginButton>
                                </LoginContent>
                            </Body>
                        </>
                    ) : (
                        <SendEmailContent>
                            <LogoSendEmailImg
                                src={LogoSendEmail}
                                alt="LogoSendEmail"
                            />
                            <TitleSendEmail>VERIFIQUE SEU E-MAIL</TitleSendEmail>
                            <DescriptionSendEmail>
                                Verifique a caixa de entrada do seu e-mail e clique
                                no link fornecido para redefinir sua senha. Caso não
                                receba o e-mail,{' '}
                                <ResendEmailButton onClick={handleResendEmail}>
                                    clique aqui para reenviar
                                </ResendEmailButton>
                            </DescriptionSendEmail>
                            <LogoMandeSeguroSendEmailImg
                                src={LogoMandeSeguro}
                                alt="LogoMandeSeguro"
                            />
                        </SendEmailContent>
                    )}
                </Content>
                <ContentWallpaper>
                    <Header>
                        <HeaderItenContent>
                            <HeaderItenText>SOBRE NÓS</HeaderItenText>
                            <HeaderItenText>REGISTRO DE OBJETOS</HeaderItenText>
                            <HeaderItenText>FALE CONOSCO</HeaderItenText>
                        </HeaderItenContent>
                        <ClientButton>
                            <ClientText>SEJA UM CLIENTE</ClientText>
                        </ClientButton>
                    </Header>
                    <LogoMandeSeguroWhiteContent>
                        <LogoMandeSeguroWhiteImg
                            src={LogoMandeSeguroWhite}
                            alt="LogoMandeSeguro"
                        />
                    </LogoMandeSeguroWhiteContent>
                </ContentWallpaper>
            </Container>
        </Form>
    );
};

export default ForgotPassword;
