import React, { ButtonHTMLAttributes, CSSProperties } from 'react';
import { Container, ButtonContent, PlusContent, Text, SubTitle } from './styles';

interface ButtonProps extends ButtonHTMLAttributes<HTMLButtonElement> {
    title: string;
    subTitle: string;

    containerStyle?: CSSProperties;
}

const RegisterButton: React.FC<ButtonProps> = ({
    title,
    subTitle,
    containerStyle,
    ...rest
}) => {
    return (
        <Container style={{ ...containerStyle }}>
            <ButtonContent {...rest}>
                <PlusContent>
                    <Text>+</Text>
                </PlusContent>

                <Text>{title}</Text>
            </ButtonContent>

            <SubTitle>{subTitle}</SubTitle>
        </Container>
    );
};

export default RegisterButton;
