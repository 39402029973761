import styled, { css } from 'styled-components';
import colors from '../../../styles/colors';

interface ActiveItem {
    active: boolean;
}

export const Container = styled.div`
    margin: 0 5px;
    height: 50px;
`;

export const Content = styled.div<ActiveItem>`
    padding: 0 15px;
    height: 100%;
    display: flex;
    align-items: center;
    cursor: pointer;

    ${({ active }) =>
        active &&
        css`
            border-bottom: 1px solid ${colors.color_4BB493};
            font-weight: bold;
        `}

    @media (max-width: 1370px) {
        font-size: 16px;

        @media (max-width: 1175px) {
            padding: 0 5px;
        }
    }
`;

export const Title = styled.p<ActiveItem>`
    font-size: 16px;
    color: ${colors.color_3F577B};

    ${({ active }) =>
        active &&
        css`
            color: ${colors.color_4BB493};
            font-weight: bold;
        `}

    @media (max-width: 1370px) {
        font-size: 14px;

        @media (max-width: 1175px) {
            font-size: 12px;
        }
    }
`;
