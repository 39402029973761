import React, { CSSProperties } from 'react';
import ServiceCard from './ServiceCard';
import {
    Container,
    ServiceContainer,
    ServicesContent,
    ServicesTitleText,
    Title,
} from './styles';

import MaloteIcon from '../../assets/svgs/icons/MaloteSelected.svg';
import DeliveryIcon from '../../assets/svgs/icons/DeliverySelected.svg';
import MensageriaIcon from '../../assets/svgs/icons/MensageriaSelected.svg';

interface HeaderChildDashboardProps {
    title: string;
    activeMalote?: boolean;
    activeDelivery?: boolean;
    activeMensageria?: boolean;
    containerStyle?: CSSProperties;
}

const HeaderChildDashboard: React.FC<HeaderChildDashboardProps> = props => {
    return (
        <Container style={{ ...props.containerStyle }}>
            <Title>{props.title}</Title>
            <ServiceContainer>
                <ServicesTitleText>Nossos Serviços</ServicesTitleText>
                <ServicesContent>
                    <ServiceCard
                        active={props.activeMalote}
                        title="Malote"
                        icon={MaloteIcon}
                    />
                    <ServiceCard
                        active={props.activeDelivery}
                        title="Delivery"
                        icon={DeliveryIcon}
                    />
                    <ServiceCard
                        active={props.activeMensageria}
                        title="Mensageria"
                        icon={MensageriaIcon}
                    />
                </ServicesContent>
            </ServiceContainer>
        </Container>
    );
};

export default HeaderChildDashboard;
