import React, { useState } from 'react';
import { Switch, useHistory, useLocation } from 'react-router-dom';

import Route from '../../../routes/Route';
import ChildDashboardContainer from '../../../components/ChildDashboardContainer';
import Header from '../../../components/Header';
import { activeTab } from '../common';
import Delivery from './Delivery';

const DeliveryRoutes: React.FC = () => {
    const history = useHistory();
    const location = useLocation();

    const [tabs, setTabs] = useState([
        {
            name: 'Delivery',
            link: '/dashboard/delivery',
            active: location.pathname === '/dashboard/delivery',
        },
        {
            name: 'Entregas',
            link: '/dashboard/delivery/entregas',
            active: location.pathname === '/dashboard/delivery/entregas',
        },
        {
            name: 'Acompanhamento',
            link: '/dashboard/delivery/acompanhamento',
            active: location.pathname === '/dashboard/delivery/acompanhamento',
        },
        {
            name: 'Registro',
            link: '/dashboard/delivery/registro',
            active: location.pathname === '/dashboard/delivery/registro',
        },
    ]);

    const changeTab = (name: string): void => {
        setTabs(activeTab(name, tabs));

        const { link } = tabs.find(tab => tab.name === name)!;

        history.push(link);
    };

    return (
        <>
            <Header tabs={tabs} changeTab={changeTab} />
            <ChildDashboardContainer>
                <Switch>
                    <Route
                        path="/dashboard/delivery"
                        exact
                        component={Delivery}
                        isPrivate
                    />
                </Switch>
            </ChildDashboardContainer>
        </>
    );
};

export default DeliveryRoutes;
