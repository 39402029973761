import styled, { css } from 'styled-components';
import colors from '../../styles/colors';
import Tooltip from '../Tooltip';

interface ContainerProps {
    isErrored: boolean;
}

export const Container = styled.div<ContainerProps>`
    display: flex;
    width: 354px;
    align-items: center;
    height: 42px;
    ${props =>
        props.isErrored &&
        css`
            border-color: #c53030;
        `}
`;

export const InputContainer = styled.input`
    padding: 0 20px;
    width: 100%;
    height: 100%;
    border-radius: 3px;
    font-size: 15px;
    border: 1px solid ${colors.color_707070};
`;

export const Error = styled(Tooltip)`
    height: 20px;
    margin-left: 16px;
    svg {
        margin: 0;
    }

    span {
        background: #c53030;
        color: #fff;

        &::before {
            border-color: #c53030 transparent;
        }
    }
`;
