import styled from 'styled-components';
import colors from '../../../../styles/colors';

export const Container = styled.div`
    margin: 20px;
    padding: 40px;
    width: calc(100% - 2 * 20px - 2 * 40px);
    height: calc(100% - 2 * 20px - 2 * 40px);
    background-color: ${colors.color_FFFFFF};
    border-radius: 5px;
    box-shadow: 0px 3px 6px #00000029;

    overflow-x: auto;
    overflow-y: auto;

    ::-webkit-scrollbar {
        width: 7px;
        height: 7px;
    }

    ::-webkit-scrollbar-thumb {
        background: ${colors.color_3F577B};
        border-radius: 5px;
        transition: 0.5s;
    }

    ::-webkit-scrollbar-track {
        background: ${colors.color_F5F6F8};
        border: 1px solid ${colors.color_BCE0FD};
        border-radius: 5px;
    }
`;

export const Title = styled.p`
    font-size: 27px;
    color: ${colors.color_3F577B};
`;

export const UnderlinedTitle = styled.div`
    width: 150px;
    height: 3px;
    background-color: ${colors.color_4BB493};
`;

export const SubTitleContent = styled.div`
    margin-top: 30px;
    max-width: 600px;
`;

export const SubTitle = styled.p`
    font-size: 16px;
    color: ${colors.color_838FAE};
`;
