import React, { CSSProperties, InputHTMLAttributes, useEffect, useRef } from 'react';
import { useField } from '@unform/core';

import colors from '../../styles/colors';
import { Container, OptionContainer, SelectContainer, Title } from './styles';

interface SelectProps extends InputHTMLAttributes<HTMLSelectElement> {
    title: string;
    name: string;
    options: Array<{
        title: string;
        value: string | number | readonly string[] | undefined;
    }>;

    placeholder?: string;
    containerStyle?: CSSProperties;
}

const Select: React.FC<SelectProps> = ({
    title,
    name,
    options,
    placeholder,
    containerStyle,
    ...rest
}) => {
    const inputRef = useRef<HTMLSelectElement>(null);
    const { fieldName, defaultValue, error, registerField } = useField(name);

    useEffect(() => {
        registerField({
            name: fieldName,
            ref: inputRef.current,
            path: 'value',
        });
    }, [fieldName, registerField]);

    return (
        <Container isErrored={!!error} style={{ ...containerStyle }}>
            <div
                style={{
                    display: 'flex',
                }}
            >
                <Title
                    style={{
                        backgroundColor: `${
                            containerStyle?.backgroundColor || colors.color_FFFFFF
                        }`,
                    }}
                >
                    {title}
                </Title>
            </div>
            <div
                style={{
                    display: 'flex',
                    width: '100%',
                    height: containerStyle?.height || '44px',
                }}
            >
                <SelectContainer
                    placeholder={placeholder || ''}
                    defaultValue={defaultValue}
                    ref={inputRef}
                    {...rest}
                >
                    {options.map((option, index) => (
                        <OptionContainer value={option.value} key={index}>
                            {option.title}
                        </OptionContainer>
                    ))}
                </SelectContainer>
            </div>
        </Container>
    );
};

export default Select;
