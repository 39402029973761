import React, { useState } from 'react';
import { Switch, useHistory, useLocation } from 'react-router-dom';

import Route from '../../../routes/Route';
import Header from '../../../components/Header';
import { activeTab } from '../common';
import Malote from './Malote';
import Envios from './Envios';
import ChildDashboardContainer from '../../../components/ChildDashboardContainer';
import Recebimentos from './Recebimentos';
import Registro from './Registro';

const MaloteRoutes: React.FC = () => {
    const history = useHistory();
    const location = useLocation();

    const [tabs, setTabs] = useState([
        {
            name: 'Malote',
            link: '/dashboard/malote',
            active: location.pathname === '/dashboard/malote',
        },
        {
            name: 'Envios',
            link: '/dashboard/malote/envios',
            active: location.pathname === '/dashboard/malote/envios',
        },
        {
            name: 'Recebimentos',
            link: '/dashboard/malote/recebimentos',
            active: location.pathname === '/dashboard/malote/recebimentos',
        },
        {
            name: 'Acompanhamento',
            link: '/dashboard/malote/acompanhamento',
            active: location.pathname === '/dashboard/malote/acompanhamento',
        },
        {
            name: 'Registro',
            link: '/dashboard/malote/registro',
            active: location.pathname === '/dashboard/malote/registro',
        },
    ]);

    const changeTab = (name: string): void => {
        setTabs(activeTab(name, tabs));

        const { link } = tabs.find(tab => tab.name === name)!;

        history.push(link);
    };

    return (
        <>
            <Header tabs={tabs} changeTab={changeTab} />
            <ChildDashboardContainer>
                <Switch>
                    <Route
                        path="/dashboard/malote"
                        exact
                        component={Malote}
                        isPrivate
                    />
                    <Route
                        path="/dashboard/malote/envios"
                        exact
                        component={Envios}
                        isPrivate
                    />
                    <Route
                        path="/dashboard/malote/recebimentos"
                        exact
                        component={Recebimentos}
                        isPrivate
                    />
                    <Route
                        path="/dashboard/malote/registro"
                        exact
                        component={Registro}
                        isPrivate
                    />
                </Switch>
            </ChildDashboardContainer>
        </>
    );
};

export default MaloteRoutes;
