import React, { useCallback, useRef, useState } from 'react';
import { FormHandles } from '@unform/core';
import { Form } from '@unform/web';
import * as Yup from 'yup';

import HeaderChildDashboard from '../../../../components/HeaderChildDashboard';
import RadioButtonSelected from '../../../../assets/svgs/icons/RadioButtonSelected.svg';
import RadioButtonNotSelected from '../../../../assets/svgs/icons/RadioButtonNotSelected.svg';

import ProfileImage from '../../../../assets/Profile.png';

import {
    Container,
    Content,
    IconTypePerson,
    ImageProfile,
    ImageContent,
    TypePersonContainer,
    TypePersonContent,
    TypePersonText,
    TypeText,
    SubmitContent,
} from './styles';
import colors from '../../../../styles/colors';
import Button from '../../../../components/Button';
import PhysicalPerson from './PhysicalPerson';
import Company from './Company';
import { useToast } from '../../../../hooks/toast';
import api from '../../../../services/api';

const SettingsUsers: React.FC = () => {
    const formRef = useRef<FormHandles>(null);
    const { addToast } = useToast();

    const [typePhysicalPerson, setTypePhysicalPerson] = useState(true);

    const handleSubmitPhysicalPerson = useCallback(
        async (data: PhysicalPerson) => {
            try {
                formRef.current?.setErrors({});
                const schema = Yup.object().shape({
                    name: Yup.string().required('name obrigatório'),
                    email: Yup.string()
                        .required('E-mail obrigatório')
                        .email('Digite um e-mail válido'),
                    identifier: Yup.string().required('cpf obrigatório'),
                    permissions: Yup.string().required('permissions obrigatório'),
                    phone_number: Yup.string().required('phone_number obrigatório'),
                    company_id: Yup.string().required('company_id obrigatório'),
                    department: Yup.string().required('department obrigatório'),
                    country: Yup.string().required('country obrigatório'),
                    gender: Yup.string().required('gender obrigatório'),
                    password: Yup.string().required('password obrigatório'),
                    confirm_password: Yup.string().required(
                        'confirm_password obrigatório',
                    ),
                });

                await schema.validate(data, {
                    abortEarly: false,
                });

                addToast({
                    type: 'success',
                    title: 'Cadastro realizado!',
                    description: 'Empresa cadastrada com sucesso!',
                });
            } catch (err) {
                addToast({
                    type: 'error',
                    title: 'Erro no cadastro',
                    description:
                        'Ocorreu um erro ao fazer cadastro, tente novamente',
                });
            }
        },
        [addToast],
    );

    const handleSubmitCompany = useCallback(
        async (data: Company) => {
            try {
                formRef.current?.setErrors({});
                const schema = Yup.object().shape({
                    fantasy_name: Yup.string().required('fantasy_name obrigatório'),
                    company_name: Yup.string().required('company_name obrigatório'),
                    cnpj: Yup.string().required('cnpj obrigatório'),
                    state_registration: Yup.string().required(
                        'state_registration obrigatório',
                    ),
                    municipal_registration: Yup.string().required(
                        'municipal_registration obrigatório',
                    ),
                    phone_number: Yup.string().required('phone_number obrigatório'),
                    cellphone_number: Yup.string().required(
                        'cellphone_number obrigatório',
                    ),
                    email: Yup.string()
                        .required('E-mail obrigatório')
                        .email('Digite um e-mail válido'),
                    status: Yup.string().required('cellphone_number obrigatório'),
                });

                await schema.validate(data, {
                    abortEarly: false,
                });

                await api.post('/companies', data);

                addToast({
                    type: 'success',
                    title: 'Cadastro realizado!',
                    description: 'Empresa cadastrada com sucesso!',
                });
            } catch (err) {
                addToast({
                    type: 'error',
                    title: 'Erro no cadastro',
                    description:
                        'Ocorreu um erro ao fazer cadastro, tente novamente',
                });
            }
        },
        [addToast],
    );

    const handleSubmit = useCallback(
        async (data: { company: Company; physicalPerson: PhysicalPerson }) => {
            if (typePhysicalPerson) {
                await handleSubmitPhysicalPerson(data.physicalPerson);
            } else await handleSubmitCompany(data.company);
        },
        [addToast, typePhysicalPerson, handleSubmitCompany],
    );

    return (
        <Container>
            <HeaderChildDashboard
                title="CADASTRO DE USUÁRIO"
                containerStyle={{ width: '95%' }}
            />
            <TypePersonContainer>
                <TypeText>Tipo</TypeText>
                <TypePersonContent
                    onClick={() => {
                        setTypePhysicalPerson(!typePhysicalPerson);
                    }}
                >
                    <IconTypePerson
                        src={
                            typePhysicalPerson
                                ? RadioButtonSelected
                                : RadioButtonNotSelected
                        }
                    />
                    <TypePersonText>Física</TypePersonText>
                </TypePersonContent>
                <TypePersonContent
                    onClick={() => {
                        setTypePhysicalPerson(!typePhysicalPerson);
                    }}
                >
                    <IconTypePerson
                        src={
                            !typePhysicalPerson
                                ? RadioButtonSelected
                                : RadioButtonNotSelected
                        }
                    />
                    <TypePersonText>Jurídica</TypePersonText>
                </TypePersonContent>
            </TypePersonContainer>

            <Form ref={formRef} onSubmit={handleSubmit}>
                <Content>
                    <ImageContent>
                        <ImageProfile src={ProfileImage} />
                        <p
                            style={{
                                marginTop: '37px',
                                fontSize: '20px',
                                color: `${colors.color_FFFFFF}`,
                            }}
                        >
                            Editar dados
                        </p>
                    </ImageContent>

                    {typePhysicalPerson && <PhysicalPerson />}

                    {!typePhysicalPerson && <Company />}
                </Content>
                <SubmitContent>
                    <Button title="Cancelar" typeButton="light" />
                    <Button title="Salvar" type="submit" />
                </SubmitContent>
            </Form>
        </Container>
    );
};

export default SettingsUsers;
