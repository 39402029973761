import styled, { css } from 'styled-components';
import colors from '../../styles/colors';

interface Button {
    typeButton: 'primary' | 'light';
}

export const Container = styled.button<Button>`
    border: none;
    width: 150px;
    height: 45px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 5px;
    font-size: 14px;

    ${({ typeButton }) => {
        switch (typeButton) {
            case 'light':
                return css`
                    border: 1px solid ${colors.color_AFAFAF};
                    background-color: ${colors.color_FAFAFA};
                    color: ${colors.color_4E5358};
                `;
            default:
                return css`
                    background-color: ${colors.color_3F577B};
                    color: ${colors.color_FFFFFF};
                `;
        }
    }}

    &:hover {
        opacity: 0.9;
    }

    &:disabled {
        cursor: default;
        opacity: 0.5;
    }
`;
