import React, { useState } from 'react';

import { Link } from 'react-router-dom';
import {
    ArrowContent,
    Container,
    LogoCompanyContent,
    LogoMandeSeguroContent,
    ProfileContent,
    ImageProfileContent,
    Tabs,
    UserContent,
    TextUserName,
    TextUserProfession,
    NotificationImageContent,
    DropdownContent,
    PositionDropdownContent,
    UserProfileContent,
    TextDropdown,
    TextDropdownContent,
} from './styles';
import LogoMandeSeguro from '../../assets/svgs/LogoMandeSeguro.svg';
import LogoUsiminas from '../../assets/svgs/LogoUsiminas.svg';
import ArrowDown from '../../assets/svgs/icons/ArrowDown.svg';
import NotificationIcon from '../../assets/svgs/icons/Notifications.svg';
import ProfileImage from '../../assets/Profile.png';
import ItemHeader from './ItemHeader';
import { Tab } from '../../pages/Dashboard/common';
import { useAuth } from '../../hooks/auth';

interface HeaderProps {
    tabs: Array<Tab>;
    changeTab(name: string): void;
}

const Header: React.FC<HeaderProps> = props => {
    const [dropdownContent, setDropdownContent] = useState(false);
    const { signOut } = useAuth();

    return (
        <Container>
            <LogoMandeSeguroContent src={LogoMandeSeguro} alt="LogoMandeSeguro" />

            <Tabs>
                {props.tabs.map((tab, index) => (
                    <ItemHeader
                        name={tab.name}
                        link={tab.link}
                        active={tab.active}
                        changeTab={props.changeTab}
                        key={index}
                    />
                ))}
            </Tabs>
            <UserContent>
                <NotificationImageContent
                    src={NotificationIcon}
                    alt="NotificationIcon"
                />
                <UserProfileContent
                    onClick={() => setDropdownContent(!dropdownContent)}
                >
                    <ProfileContent>
                        <TextUserName>Caio Souza</TextUserName>
                        <TextUserProfession>Analista - Expedição</TextUserProfession>
                    </ProfileContent>
                    <ImageProfileContent src={ProfileImage} alt="ProfileImage" />
                    <ArrowContent
                        active={dropdownContent}
                        src={ArrowDown}
                        alt="ArrowDown"
                    />
                    <PositionDropdownContent>
                        <DropdownContent active={dropdownContent}>
                            <Link to="/dashboard/settings/cadastroDeUsuario">
                                <TextDropdownContent>
                                    <TextDropdown>Perfil</TextDropdown>
                                </TextDropdownContent>
                            </Link>
                            <TextDropdownContent>
                                <TextDropdown>Configurações</TextDropdown>
                            </TextDropdownContent>
                            <TextDropdownContent>
                                <TextDropdown>Notificações</TextDropdown>
                            </TextDropdownContent>
                            <TextDropdownContent onClick={signOut}>
                                <TextDropdown>Sair</TextDropdown>
                            </TextDropdownContent>
                        </DropdownContent>
                    </PositionDropdownContent>
                </UserProfileContent>
                <LogoCompanyContent src={LogoUsiminas} alt="LogoUsiminas" />
            </UserContent>
        </Container>
    );
};

export default Header;
